import { BORER_PAGINATION_LIMIT, EMPTY_GUID } from '../utilities/constants';
import { client, gql } from './Client';

const listEquipmentForTypeQuery = gql`
  query listEquipmentForType($siteId: ID!, $limit: Int!, $lastId: ID!) {
    listEquipmentForType(siteId: $siteId, equipmentType: Borer, limit: $limit, lastId: $lastId) {
      id
      longName
      shortName
      isActive
      assignedPanel {
        id
        description
        block {
          description
        }
        miningMethod {
          layer
          name
          zone
        }
      }
    }
  }
`;

export interface Borer {
  id: string;
  longName: string;
  shortName: string | null;
  isActive: boolean;
  assignedPanel: {
    id: string;
    description: string | null;
    block: {
      description: string | null;
    };
    miningMethod: {
      layer: string;
      name: string;
      zone: string;
    };
  };
}

export interface ListBorersResult {
  data: {
    listEquipmentForType: Borer[];
  };
}

const listBorers = (siteId: string, limit = BORER_PAGINATION_LIMIT, lastId = EMPTY_GUID) =>
  client.query({
    query: listEquipmentForTypeQuery,
    variables: { siteId, limit, lastId },
    fetchPolicy: 'no-cache',
  });

export { listBorers };
