/* eslint-disable import/no-cycle */
import * as Sentry from '@sentry/react';
import type { RxCollection, RxDocument } from 'rxdb';
import type {
  RxReplicationError,
  RxReplicationPullError,
  RxReplicationPushError,
} from 'rxdb/dist/types/plugins/replication';
import { RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql';

import { rootStore } from '../mobx-models/Root';
import { trackEventFn } from '../utilities/hooks/useEventTracking';
import { getBorerShortName } from '../utilities/utilityFunctions';
import { RxdbCollectionName } from './rxdbCollectionName';
import { refreshTokenOnSyncState } from './syncReplicationStatesWithGraphQL';

export const DEBUG = localStorage.getItem('DEBUG') === 'true';

const unauthorizedErrorType = 'UnauthorizedException';

export const checkForExpiredToken = (err: RxReplicationError<any>) => {
  return (
    err?.innerErrors &&
    Array.isArray(err?.innerErrors) &&
    err.innerErrors?.find(innerError => innerError?.errorType === unauthorizedErrorType) !==
      undefined
  );
};

export const hasForeignKeyErrors = (err: RxReplicationError<any>) => {
  // If you need to add more foreign key error handling it can be done here
  return (
    err.innerErrors?.[0]?.errorType?.includes('RecordViolatesForeignKeyConstraintException') ||
    err.innerErrors?.[0]?.message?.includes('foreign key constraint')
  );
};

const hasServiceOfflineErrors = (err: RxReplicationError<any>) => {
  // If you need to add more foreign key error handling it can be done here
  return (
    err.innerErrors?.[0]?.errorType?.includes('MinesightOperationsApiUnavailableException') ||
    err.innerErrors?.[0]?.message?.includes('MinesightOperationsApiUnavailableException')
  );
};

const recordIdsFailedToSyncCount: Record<string, number> = {};

/** @type {*} The connections between the rxdb collections and their foreignKeys */
const documentRemovalRelationships: Record<
  string,
  {
    collectionName: string;
    foreignKey: string;
  }[]
> = {
  [RxdbCollectionName.SIGNATURES]: [
    {
      collectionName: RxdbCollectionName.BORER_SHIFT_SIGNATURE,
      foreignKey: 'signatureId',
    },
    {
      collectionName: RxdbCollectionName.INSPECTION_RESULTS,
      foreignKey: 'signatureId',
    },
  ],
  [RxdbCollectionName.GROUND_HAZARDS]: [
    {
      collectionName: RxdbCollectionName.GROUND_HAZARDS_ATTACHMENTS,
      foreignKey: 'groundHazardId',
    },
    {
      collectionName: RxdbCollectionName.HAZARD_LOGS,
      foreignKey: 'groundHazard',
    },
    {
      collectionName: RxdbCollectionName.GROUND_CONTROL_SETS,
      foreignKey: 'groundHazardId',
    },
  ],
  [RxdbCollectionName.EQUIPMENT_DEFICIENCY]: [
    {
      collectionName: RxdbCollectionName.EQUIPMENT_DEFICIENCY_ATTACHMENT,
      foreignKey: 'equipmentDeficiencyId',
    },
    {
      collectionName: RxdbCollectionName.EQUIPMENT_DEFICIENCY_LOG,
      foreignKey: 'equipmentDeficiencyId',
    },
  ],
  [RxdbCollectionName.BORER_SHIFT_CREW]: [
    {
      collectionName: RxdbCollectionName.BORER_SHIFT_CREW_MEMBER,
      foreignKey: 'borerShiftCrewId',
    },
  ],
};

/**
 * Removes a single document from rxdb, and tracks it in the event tracking system and sentry
 *
 * @param {RxDocument} document
 * @param {RxCollection<any>} collection
 * @param {string} errMessage
 * @param {string} errType
 */
const executeDocumentRemoval = async (
  document: RxDocument<any>,
  collection: RxCollection<any>,
  errMessage: string,
  errType: string,
) => {
  try {
    await trackEventFn(
      'RXDB_PUSH_FAILURE_DOC_REMOVAL',
      {
        collection: collection?.name || 'UNKNOWN',
        documentId: document?.id || 'UNKNOWN',
        document: JSON.stringify(document),
        errMessage,
        errType,
        documentRemoval: true,
      },
      DEBUG,
    );

    if (!document.deleted) await document.remove();
    await collection.storageInstance.cleanup(0);

    delete recordIdsFailedToSyncCount[document.id];
  } catch (error) {
    console.log('Error removing document...', error, document);
    Sentry.captureException(error, {
      tags: {
        rxDBError: true,
        removingDocumentError: true,
        collectionName: collection?.name,
        documentRemoval: true,
        shiftString: rootStore.shiftPicker.shiftString,
      },
    });
  }
};

/**
 * Handles the removal of documents that have failed to sync
 * Checks if the document has any relationships that need to be removed as well
 *
 * @param {string} documentId
 * @param {RxCollection<any>} collection
 * @param {string} errMessage
 * @param {string} errType
 */
const handleDocumentRemoval = async (
  documentId: string,
  collection: RxCollection<any>,
  errMessage: string,
  errType: string,
) => {
  const originalDoc: RxDocument = await collection
    .findOne({
      selector: {
        id: documentId,
      },
    })
    .exec();

  if (!originalDoc) {
    console.log('Document not found in collection...', documentId, collection);
    return;
  }

  if (DEBUG) console.log('Document to be removed...', originalDoc);

  let docsToRemove: Array<{ collection: RxCollection; document: RxDocument }> = [
    { collection, document: originalDoc },
  ];

  // if the document has no relationships, just remove it
  if (!documentRemovalRelationships.hasOwnProperty(collection.name)) {
    if (DEBUG) console.info('No relationships found for collection...', collection.name);

    await executeDocumentRemoval(originalDoc, collection, errMessage, errType);
    return;
  }

  // Check if the document has any relationships with docs that need to be removed
  const relationships = documentRemovalRelationships[collection.name];

  for (const relationship of relationships) {
    const { collectionName, foreignKey } = relationship;
    const relationshipCollection = collection.database.collections[collectionName];

    if (relationshipCollection) {
      const relatedDocs = await relationshipCollection
        .find({
          selector: {
            [foreignKey]: documentId,
          },
        })
        .exec();

      if (relatedDocs) {
        if (DEBUG)
          console.log(
            `Found ${relatedDocs.length} related docs in ${collectionName} to remove...`,
            relatedDocs,
          );

        docsToRemove = [
          ...docsToRemove,
          ...relatedDocs.map(doc => ({ collection: relationshipCollection, document: doc })),
        ];
      }
    }
  }

  if (DEBUG) console.info('Documents to be removed...', docsToRemove);

  // remove docs in reverse order so that the original doc is removed last, ie remove the children linked to the original doc first
  for (const { collection: col, document: doc } of docsToRemove.reverse()) {
    if (DEBUG) console.log('Removing document...', doc);
    await executeDocumentRemoval(doc, col, errMessage, errType);
    if (DEBUG) console.log('Document removed...');
  }
};

const recordFailedToSyncRecord = (recordId: string) => {
  if (!recordIdsFailedToSyncCount[recordId]) {
    recordIdsFailedToSyncCount[recordId] = 1;
  }
  recordIdsFailedToSyncCount[recordId] += 1;
};

const recordIdHasFailedToSyncMoreThanXTimes = (recordId: string, xTimes = 10) => {
  return recordIdsFailedToSyncCount[recordId] >= xTimes;
};

/**
 * For a given record return the number of times it has failed to sync
 *
 * @param {string} recordId
 */
const errorReportedToSentry = (recordId: string) => recordIdsFailedToSyncCount[recordId] > 0;

const captureRxdbExceptionInSentry = (
  err: RxReplicationPushError<any>,
  collectionState: RxGraphQLReplicationState<any>,
  additionalFields: { [key: string]: string | boolean | undefined } = {},
) => {
  const documentId = err.documentsData[0].id;
  const shortName = getBorerShortName();
  const hasExpiredToken = checkForExpiredToken(err);
  const hasNotSelectedBorer = err.innerErrors?.[0]?.message?.includes(
    "Variable 'borerEquipmentId' has coerced Null",
  );

  Sentry.captureException(err, {
    contexts: {
      documentData: {
        documentData: JSON.stringify(err.documentsData),
        error: JSON.stringify(err.innerErrors),
      },
    },
    extra: { documentData: JSON.stringify(err.documentsData) },
    tags: {
      rxDBError: true,
      shortName,
      hasExpiredToken,
      hasNotSelectedBorer,
      hasForeignKeyErrors: hasForeignKeyErrors(err),
      collectionName: collectionState?.collection?.name,
      shiftString: rootStore.shiftPicker.shiftString,
      documentId,
      documentRemoval: false,
      ...additionalFields,
    },
  });

  recordFailedToSyncRecord(documentId);
};

//  ----------- Pull handling ------------
const captureRxdbPullExceptionInSentry = (
  err: RxReplicationPullError<any>,
  collectionState: RxGraphQLReplicationState<any>,
  additionalFields: { [key: string]: string | boolean | undefined } = {},
) => {
  const { latestPulledDocument } = err;
  const shortName = getBorerShortName();
  const hasExpiredToken = checkForExpiredToken(err);
  const hasNotSelectedBorer = err.innerErrors?.[0]?.message?.includes(
    "Variable 'borerEquipmentId' has coerced Null",
  );

  Sentry.captureException(err, {
    contexts: {
      documentData: {
        error: JSON.stringify(err.innerErrors),
      },
    },
    tags: {
      rxDBError: true,
      shortName,
      hasExpiredToken,
      hasNotSelectedBorer,
      hasForeignKeyErrors: hasForeignKeyErrors(err),
      collectionName: collectionState?.collection?.name,
      shiftString: rootStore.shiftPicker.shiftString,
      lastPulledDocumentId: latestPulledDocument?.id,

      ...additionalFields,
    },
  });
};

const collectionsFailedToPullCount: Record<string, number> = {}; // key here is a composite of the collection name and the last pulled document id
const incrementCollectionFailedToPullCount = (
  collectionName: string,
  lastPulledDocumentId: string,
) => {
  const key = `${collectionName}-${lastPulledDocumentId}`;
  if (!collectionsFailedToPullCount[key]) {
    collectionsFailedToPullCount[key] = 1;
  }
  collectionsFailedToPullCount[key] += 1;
};

const collectionHasFailedToPullMoreThanXTimes = (
  collectionName: string,
  lastPulledDocumentId: string,
  xTimes = 10,
) => {
  const key = `${collectionName}-${lastPulledDocumentId}`;
  return collectionsFailedToPullCount[key] >= xTimes;
};

const handlePullError = (
  err: RxReplicationPullError<any>,
  collectionState: RxGraphQLReplicationState<any>,
) => {
  const { latestPulledDocument } = err;

  if (!latestPulledDocument) {
    return;
  }

  const collectionName = collectionState?.collection?.name;
  const lastPulledDocumentId = latestPulledDocument?.id;

  if (collectionHasFailedToPullMoreThanXTimes(collectionName, lastPulledDocumentId, 5)) {
    captureRxdbPullExceptionInSentry(err, collectionState, {
      replicationPullError: true,
    });
  } else {
    incrementCollectionFailedToPullCount(collectionName, lastPulledDocumentId);
  }
};

/**
 * Handle the errors that occur during replication push/pull
 *
 * @param {RxGraphQLReplicationState<any>} collectionState
 */
export const handleReplicationErrors = (collectionState: RxGraphQLReplicationState<any>) => {
  collectionState.error$.subscribe(async err => {
    // Get the borers name from local storage
    const hasExpiredToken = checkForExpiredToken(err);

    // if token has expired then refresh it, dont report error
    if (hasExpiredToken) {
      await refreshTokenOnSyncState(collectionState, 6 * 60 * 60);
      return;
    }

    // Check if a borer is selected
    const hasNotSelectedBorer = err.innerErrors?.[0]?.message?.includes(
      "Variable 'borerEquipmentId' has coerced Null",
    );

    if (err) {
      const { type, message } = err;
      // If debug then show the error
      if (DEBUG) {
        console.error(`rxDB Error -  ${type}ing Data from Server`, JSON.stringify(err));
        console.dir(err);
      }

      if (!DEBUG && message === 'Failed to fetch') {
        // Network failed dont report error
        return;
      }

      if (hasServiceOfflineErrors(err)) {
        // Service is offline
        return;
      }

      // Generic error related to network failure
      if (message === 'Load failed') {
        return;
      }

      // if a borer is not selected then dont report error
      if (hasNotSelectedBorer) {
        return;
      }

      if (type === 'pull') {
        handlePullError(err, collectionState);
        return;
      } else if (type === 'push') {
        const documentId = err.documentsData[0].id as string;
        // This is handled gracefully in handleOperatorStateFeedEvents.ts
        if (collectionState.collection.name === RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED) {
          return;
        }

        // Don't remove documents if not on latest version
        if (rootStore?.appVersion?.hasNewUpdate) {
          captureRxdbExceptionInSentry(err, collectionState, {
            hasNewUpdateAvailable: true,
            replicationPushError: true,
          });
          return;
        }

        // If the document has failed to sync more than 10 times then remove it
        if (recordIdHasFailedToSyncMoreThanXTimes(documentId)) {
          captureRxdbExceptionInSentry(err, collectionState, {
            replicationPushError: true,
            documentRemoval: true,
          });

          await handleDocumentRemoval(documentId, collectionState.collection, message, type);
          return;
        }

        // If we have not reported the error, and its happened at least once already, report to sentry
        if (
          !errorReportedToSentry(documentId) &&
          recordIdHasFailedToSyncMoreThanXTimes(documentId, 1)
        ) {
          captureRxdbExceptionInSentry(err, collectionState, {
            replicationPushError: true,
          });
          recordFailedToSyncRecord(documentId);
          return;
        } else {
          //increment the number of times this document has failed to sync
          recordFailedToSyncRecord(documentId);
          return;
        }
      } else {
        // Error on unknown type, report it
        captureRxdbExceptionInSentry(err, collectionState, {
          replicationUnknownError: true,
        });
        return;
      }
    } else {
      // General error, like local DB connection issue
      console.error('rxDB Error - General Error', JSON.stringify(err));
      console.dir(err);
      captureRxdbExceptionInSentry(err, collectionState, {
        replicationUnknownError: true,
      });
    }
  });
};
