import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import {
  generateBaseEntityWithCreatedOn,
  getUnixMillisecondTimestamp,
} from '../rxdbUtilityFunctions';
import { Advance, AdvanceCollection } from './queryBuilder';

export const useAdvances = () => {
  const advancesCollection: AdvanceCollection = useRxCollection(
    RxdbCollectionName.BORER_SHIFT_ADVANCE,
  );

  const [advancesInitialized, setAdvancesInitialized] = useState(false);

  useEffect(() => {
    if (advancesCollection) setAdvancesInitialized(true);
  }, [advancesCollection]);

  const createAdvance = async (productionId: string, advance: Advance) => {
    const prodBaseEntity = generateBaseEntityWithCreatedOn();

    const doc: Advance = {
      ...prodBaseEntity,
      borerShiftProductionId: productionId,
      distance: advance.distance,
      isDeleted: advance.isDeleted || false,
      startDistance: advance.startDistance,
      endDistance: advance.endDistance,
    };

    // check if advance distance is valid
    if (doc.startDistance !== null && doc.endDistance !== null) {
      const distance = Math.abs(doc.startDistance - doc.endDistance);
      if (distance !== doc.distance) {
        doc.distance = distance;
      }
    }

    return advancesCollection?.insert(doc);
  };

  const updateAdvance = async (advance: Advance) => {
    const doc: Advance = {
      id: advance.id,
      borerShiftProductionId: advance.borerShiftProductionId,
      updatedAt: getUnixMillisecondTimestamp(),
      createdOn: advance.createdOn,
      version: advance.version,
      isDeleted: advance.isDeleted || false,
      distance: advance.distance,
      startDistance: advance.startDistance,
      endDistance: advance.endDistance,
    };

    // if start or end distance is empty, set to null, only for chevron
    if (doc.startDistance === '') {
      doc.startDistance = null;
    }
    if (doc.endDistance === '') {
      doc.endDistance = null;
    }

    // check if advance distance is valid
    if (doc.startDistance !== null && doc.endDistance !== null) {
      const distance = Math.abs(doc.startDistance - doc.endDistance);
      if (distance !== doc.distance) {
        doc.distance = distance;
      }
    }

    return advancesCollection?.upsert(doc);
  };

  const deleteAdvance = async (advanceId: string) => {
    const doc = await advancesCollection
      ?.findOne({
        selector: {
          id: advanceId,
        },
      })
      .exec();
    await doc?.remove();
  };

  return {
    advancesInitialized,
    createAdvance,
    updateAdvance,
    deleteAdvance,
    advancesCollection,
  };
};

export default useAdvances;
