import { i18n } from '@nutrien/cxp-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Employee } from '../../rxdb/Employees/queryBuilder';
import useEmployees from '../../rxdb/Employees/useEmployees';
import useEquipment from '../../rxdb/Equipment/useEquipment';
import { EquipmentDeficiencyDocument } from '../../rxdb/EquipmentDeficiency/queryBuilder';
import useEquipmentDeficiency from '../../rxdb/EquipmentDeficiency/useEquipmentDeficiency';
import useEquipmentDeficiencyAttachments from '../../rxdb/EquipmentDeficiencyAttachments/useEquipmentDeficiencyAttachments';
import useEquipmentDeficiencyLog from '../../rxdb/EquipmentDeficiencyLog/useEquipmentDeficiencyLog';
import { EquipmentType } from '../../rxdb/EquipmentType/queryBuilder';
import useEquipmentType from '../../rxdb/EquipmentType/useEquipmentType';
import { useNotification } from '../../utilities';
import { DeficiencyPanelTypes, DeficiencyStatus } from '../../utilities/enums';
import { getFormattedImages } from '../../utilities/utilityFunctions';
import DeficiencyAddPhotosPanel from '../DeficiencyAddPhotosPanel/DeficiencyAddPhotosPanel';
import DeficiencyDetailsExpansionPanel from '../DeficiencyDetailsExpansionPanel';
import { EquipmentWithType } from '../DeficiencyDetailsExpansionPanel/DeficiencyDetailsExpansionPanel';
import DeficiencyStatusExpansionPanel from '../DeficiencyStatusExpansionPanel';
import GenericSidePanel from '../GenericSidePanel';

interface Props {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  onCancel?: () => void;
  onDeficiencyAdded?: (deficiencyId: string) => void;
  preopDeficiencyUndo?: () => void;
  equipmentTypeId?: string;
}

const AddDeficiencySidePanel: React.FC<Props> = ({
  open,
  onOpen,
  onClose,
  onCancel,
  onDeficiencyAdded,
  preopDeficiencyUndo,
  equipmentTypeId,
}: Props) => {
  const { employeesList: employees } = useEmployees({
    isActive: true,
    onlyConstructionAndProduction: false,
    populateCrew: true,
    populatedPosition: true,
    onlyActiveCrew: false,
  });
  const { listEquipmentType, equipmentTypesInitialized } = useEquipmentType();
  const { createEquipmentDeficiencyLog, deficiencyLogInitialized } = useEquipmentDeficiencyLog();
  const { createEquipmentDeficiency, deficiencyCollectionsInitialized } = useEquipmentDeficiency();
  const { deficiencyAttachmentsInitialized, createEquipmentDeficiencyAttachments } =
    useEquipmentDeficiencyAttachments();
  const { listEquipment, equipmentInitialized, listAssignedEquipment } = useEquipment();

  const { successNotification, errorNotification } = useNotification();

  // Side Panel Controls
  const [hasEdits, setHasEdits] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [undo, setUndo] = useState(false);
  const [expandedPanelId, setExpandedPanel] = useState<DeficiencyPanelTypes | undefined>(
    DeficiencyPanelTypes.DETAILS,
  );

  // Equipment Types
  const [equipmentTypes, setEquipmentTypes] = useState<EquipmentType[]>([]);
  const [selectedEquipmentType, setSelectedEquipmentType] = useState<EquipmentType>();
  const [disableEquipmentField, setDisableEquipmentField] = useState(false);

  const onSetUndo = (value: boolean) => {
    preopDeficiencyUndo?.();
    setUndo(value);
  };

  // Expansion Panel Controls
  const onExpanded = (expanded: boolean, expansionPanelId: DeficiencyPanelTypes) => {
    if (expanded) setExpandedPanel(expansionPanelId);
    else setExpandedPanel(undefined);
  };

  // Details Panel
  // Employees
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();

  const onSelectEmployee = (employee: Employee) => {
    setSelectedEmployee(employee);
    setHasEdits(true);
  };

  // Equipment
  const [allEquipment, setAllEquipment] = useState<EquipmentWithType[]>([]);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentWithType>();

  // Getting Equipment
  const getAllEquipment = useCallback(async () => {
    try {
      if (equipmentTypeId) {
        const assignedEquipment = await listAssignedEquipment(equipmentTypeId);
        setAllEquipment(assignedEquipment);

        if (assignedEquipment.length === 1 && assignedEquipment[0]?.isBorer) {
          setDisableEquipmentField(true);
        } else {
          setDisableEquipmentField(false);
        }
      } else {
        setAllEquipment(await listEquipment());
        setDisableEquipmentField(false);
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: AddDeficiencySidePanel.tsx ~ line 139 ~ getAllEquipment ~ error',
        error,
      );
    }
  }, [listEquipment, listAssignedEquipment, equipmentTypeId]);

  useEffect(() => {
    if (equipmentInitialized) getAllEquipment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentInitialized, equipmentTypeId]); // Did not include getAllEquipment in deps because it results in repeat rerenders on the page

  // Equipment Change Handlers
  const onEquipmentChanged = (value: EquipmentWithType) => {
    setSelectedEquipment(value);
  };

  const getEquipmentTypeForId = () => {
    const type = equipmentTypes.filter(equipmentType => equipmentType.id === equipmentTypeId);
    if (type.length === 1) setSelectedEquipmentType(type[0]);
  };

  const getEquipmentTypes = useCallback(async () => {
    try {
      setEquipmentTypes(await listEquipmentType());
    } catch (error) {
      console.log(
        '🚀 ~ file: AddDeficiencySidePanel.tsx ~ line 87 ~ getEquipmentTypes ~ error',
        error,
      );
    }
  }, [listEquipmentType]);

  useEffect(() => {
    if (equipmentTypesInitialized && equipmentTypes.length <= 0) getEquipmentTypes();
  }, [equipmentTypesInitialized, getEquipmentTypes, equipmentTypes]);

  const onEquipmentType = (equipmentType: EquipmentType) => {
    setSelectedEquipmentType(equipmentType);
    setHasEdits(true);
  };

  // Deficiency Type
  const [deficiencyType, setDeficiencyType] = useState<string>('');
  const [deficiencyTypeErrorText, setDeficiencyTypeErrorText] = useState<string>('');

  const onDeficiencyTypeChanged = (value: string) => {
    setDeficiencyType(value);

    if (value === '') setDeficiencyTypeErrorText('Required.');
    else setDeficiencyTypeErrorText('');

    setHasEdits(true);
  };

  // Comment
  const [comment, setComment] = useState<string>('');

  const onCommentChanged = (value: string) => {
    setComment(value);
    setHasEdits(true);
  };

  // Work Order Checkbox
  const [workOrderRequired, setWorkOrderRequired] = useState<boolean>(true);

  const onWorkOrderRequired = (checked: boolean) => {
    setWorkOrderRequired(checked);
  };

  // Status
  const [status, setStatus] = useState<DeficiencyStatus>(DeficiencyStatus.ActiveDeficiency);

  const onStatusSelected = (newStatus: DeficiencyStatus) => {
    setStatus(newStatus);
  };

  useEffect(() => {
    if (workOrderRequired) setStatus(DeficiencyStatus.WOPendingReview);
    else {
      setStatus(DeficiencyStatus.ActiveDeficiency);
    }
  }, [workOrderRequired]);

  // Photos
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  const onFilesSelected = (images: File[]) => {
    setSelectedImages(images);
    setHasEdits(true);
  };

  // Input Validation
  const canSave = useMemo(() => {
    if (!deficiencyCollectionsInitialized) return false;
    if (!deficiencyLogInitialized) return false;
    if (!deficiencyAttachmentsInitialized) return false;

    // Form Logic
    if (!selectedEmployee) return false;
    if (!selectedEquipment?.equipment?.id) return false;
    if (!deficiencyType) return false;
    return true;
  }, [
    deficiencyCollectionsInitialized,
    deficiencyLogInitialized,
    deficiencyAttachmentsInitialized,
    selectedEmployee,
    selectedEquipment,
    deficiencyType,
  ]);

  // Save/Create
  const onSave = async () => {
    setIsSaving(true);

    if (!selectedEquipment) return;

    // Create Deficiency
    let deficiency: EquipmentDeficiencyDocument | undefined;
    try {
      deficiency = await createEquipmentDeficiency(
        deficiencyType,
        selectedEquipment?.equipment.id,
        workOrderRequired,
        status === DeficiencyStatus.FullyAddressed,
      );
    } catch (error) {
      console.log('🚀 ~ file: AddDeficiencySidePanel.tsx ~ line 134 ~ onSave ~ error', error);
      errorNotification('Equipment deficiency could not be saved. Please try again.');
      return;
    }

    if (!deficiency || !selectedEmployee) return;

    // Create Log
    try {
      await createEquipmentDeficiencyLog(
        comment === '' ? ' ' : comment,
        selectedEmployee.id,
        deficiency.id,
      );
    } catch (error) {
      errorNotification('Equipment Deficiency comment could not be created.');
    }

    // Create Attachments
    const attachments = getFormattedImages(
      `${selectedEquipment.typeName}
   ${selectedEquipment.equipment.longName}_${deficiency.description}_`
        .split('/')
        .join('-'),
      selectedImages,
      [],
    );
    try {
      await createEquipmentDeficiencyAttachments(deficiency.id, attachments, selectedEmployee.id);
    } catch (error) {
      console.log('🚀 ~ file: AddDeficiencySidePanel.tsx ~ line 190 ~ onSave ~ error', error);
      errorNotification('Equipment deficiency comment could not be saved. Please try again.');
      return;
    }

    if (onDeficiencyAdded) onDeficiencyAdded(deficiency.id);

    successNotification('Deficiency added');
    onClose();
  };

  useEffect(() => {
    if (open === true && undo === false) {
      setHasEdits(false);
      setIsSaving(false);
      setExpandedPanel(DeficiencyPanelTypes.DETAILS);
      setSelectedEmployee(undefined);
      setSelectedEquipmentType(undefined);
      setSelectedEquipment(undefined);
      setDeficiencyType('');
      setComment('');
      if (!workOrderRequired) setStatus(DeficiencyStatus.ActiveDeficiency);
      setWorkOrderRequired(true);
      setSelectedImages([]);

      setDisableEquipmentField(false);
      if (equipmentTypeId) getEquipmentTypeForId();
    }
    if (open === true) {
      setUndo(false);
    }
  }, [open, equipmentTypeId]);

  if (!open) return null;
  return (
    <GenericSidePanel
      open={open}
      onClose={onClose}
      title={i18n.t('Add deficiency')}
      titleForTestAttributes="deficiency"
      onOpen={onOpen}
      hasEdits={hasEdits}
      canSave={canSave}
      isSaving={isSaving}
      setUndo={onSetUndo}
      onSave={onSave}
      onCancel={onCancel}
      discardNotificationText="Deficiency draft discarded"
    >
      <DeficiencyDetailsExpansionPanel
        expanded={expandedPanelId === DeficiencyPanelTypes.DETAILS}
        onExpanded={onExpanded}
        employees={employees}
        onEmployeeSelected={onSelectEmployee}
        selectedEmployee={selectedEmployee}
        onDeficiencyTypeChanged={onDeficiencyTypeChanged}
        deficiencyType={deficiencyType}
        deficiencyTypeErrorText={deficiencyTypeErrorText}
        onCommentChanged={onCommentChanged}
        comment={comment}
        onWorkOrderRequired={onWorkOrderRequired}
        workOrderRequired={workOrderRequired}
        equipmentTypes={equipmentTypes}
        equipmentType={selectedEquipmentType}
        onEquipmentTypeChanged={onEquipmentType}
        disableAllInputs={isSaving}
        equipments={allEquipment}
        onEquipmentChanged={onEquipmentChanged}
        equipment={selectedEquipment}
        disableEquipmentField={disableEquipmentField}
        equipmentTypeId={equipmentTypeId}
      />
      <DeficiencyAddPhotosPanel
        onExpanded={onExpanded}
        expanded={expandedPanelId === DeficiencyPanelTypes.PHOTOS}
        selectedImages={selectedImages}
        onFilesSelected={onFilesSelected}
        disableAllInputs={isSaving}
      />
      <DeficiencyStatusExpansionPanel
        expanded={expandedPanelId === DeficiencyPanelTypes.STATUS}
        onExpanded={onExpanded}
        selectedStatus={status}
        onStatusSelected={onStatusSelected}
        workOrderRequired={workOrderRequired}
        disableAllInputs={isSaving}
      />
    </GenericSidePanel>
  );
};

export default AddDeficiencySidePanel;
