/* eslint-disable no-await-in-loop */
import './App.css';

import DayjsUtils from '@date-io/dayjs';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ColorThemeWrapper, darkTheme, SnackbarProvider } from '@nutrien/cxp-components';
import { useFlags } from '@nutrien/minesight-utility-module';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Provider as RXDBProvider } from 'rxdb-hooks';

import useCacheFlagValues from '@/utilities/hooks/useCacheFlagValues';

import { version } from '../../../package.json';
import { useMst } from '../../mobx-models/Root';
import RxdbCollectionName from '../../rxdb/rxdbCollectionName';
import useRxDB from '../../rxdb/useRxDB';
import { useConstructor, useOnlineStatus } from '../../utilities';
import { SYNC_DURATION_OVERRIDE, USER_SITE_ID } from '../../utilities/constants';
import useInterval from '../../utilities/hooks/useInterval';
import useLastSyncTime from '../../utilities/hooks/useLastSyncTime';
import { getLastSyncDiff } from '../../utilities/syncHelpers';
import { USER_TIMEZONE } from '../../utilities/useDateFormatters';
import useWindowVisibility from '../../utilities/useWindowVisibility';
import { getBorerShortName } from '../../utilities/utilityFunctions';
import AddAreaCheck from '../AddAreaCheck';
import AddWorkFaceAreaInspection from '../AddWorkFaceAreaInspection';
import AppBarController from '../AppBarController';
import AreaCheckPage from '../AreaCheckPage';
import { BorerHeartbeat } from '../BorerHeartbeat/BorerHeartbeat';
import { useBorerHeartbeat } from '../BorerHeartbeat/useBorerHeartbeat';
import CacheUpdateHandler from '../CacheUpdateHandler';
import Equipment from '../Equipment';
import Home from '../Home';
import Loading from '../Loading';
import Advances from '../pages/Advances';
import PiSightPage from '../pages/DelaysAndActivitiesV2';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import PWAUpdate from '../PWAUpdate';
import ShiftEnd from '../ShiftEnd';
import ShiftStart from '../ShiftStart';
import Signatures from '../Signatures';
import TabBar from '../TabBar';

dayjs.extend(timezone);
dayjs.tz.setDefault(USER_TIMEZONE);

const App: React.FC = () => {
  const { db } = useRxDB();
  useCacheFlagValues();

  const online = useOnlineStatus();
  const { equipment, user } = useMst();
  const { identifyUser } = useFlags();
  const lastBorerStateSyncTime = useLastSyncTime(RxdbCollectionName.BORER_OPERATOR_STATE_FEED);
  const windowVisible = useWindowVisibility();
  const { sendBorerHeartbeat } = useBorerHeartbeat();
  const { location } = window;

  const [showLoadingModal, setShowLoadingModal] = useState(false);

  useConstructor(async () => {
    await Promise.all([user.getProfile()]);
  });

  const identifyUserInLD = async () => {
    try {
      const currentSession = await Auth.currentSession();

      if (currentSession?.idToken?.payload?.email) {
        const { email } = currentSession.idToken.payload;
        identifyUser(email, {
          mineSightBorer: 'true',
          site: user.getSiteName(),
          siteId: user.siteId || USER_SITE_ID,
          version,
        });
      }
    } catch (err) {
      console.log('🚀 ~ file: App.tsx ~ line 105 ~ err', err);
    }
  };

  useEffect(() => {
    //  On opening app, check to see if latest data is available
    const recentSyncSeconds = getLastSyncDiff();
    let syncInterval = 3600;
    const syncOverride = localStorage.getItem(SYNC_DURATION_OVERRIDE);
    if (windowVisible && syncOverride) {
      syncInterval = JSON.parse(syncOverride);
      console.log(
        `Sync override: ${syncInterval} seconds. Last full sync was ${recentSyncSeconds} seconds ago.`,
      );
    }

    const syncWithLoadingModal = async () => {
      setShowLoadingModal(true);

      if (db) {
        await db.refreshTokensOnAllCollections();
        await db.runSingleReplication();
        setShowLoadingModal(false);
      }
    };

    if (windowVisible && recentSyncSeconds && recentSyncSeconds >= syncInterval && online) {
      syncWithLoadingModal();
    } else if (!online) {
      setShowLoadingModal(false);
    }

    // Identify user with LD
    identifyUserInLD();
  }, [windowVisible, online]);

  // Refresh tokens on 1 hour interval
  useInterval(() => {
    if (db && online) {
      db.refreshTokensOnAllCollections();
    }
  }, 1 * 60 * 60 * 1000);

  const checkIfAuthenticated = async () => {
    try {
      // Get session
      const currentSession = await Auth.currentSession();

      // Setup user in sentry
      if (currentSession?.idToken?.payload?.email) {
        const { email } = currentSession.idToken.payload;
        Sentry.setUser({ email });
      }

      // Check for valid session
      if (!currentSession.isValid() && location.pathname.indexOf('login') === -1) {
        throw new Error('Invalid session');
      }
    } catch (err) {
      if (err && err?.code === 'NetworkError') {
        // Don't re-direct to login if token expired while offline
        const loggedInPreviously = localStorage.getItem('lastOnline');
        if (loggedInPreviously) return;
      }

      if (
        err &&
        err === 'The user is not authenticated' &&
        location.pathname.indexOf('login') === -1
      )
        Sentry.captureException(err, {
          tags: {
            shortName: getBorerShortName(),
            redirectToLogin: true,
          },
        });

      if (location.pathname.indexOf('login') === -1) {
        location.href = '/login';
      }
    }
  };

  useEffect(() => {
    if (location.pathname.indexOf('logout') === -1) checkIfAuthenticated();
  }, []);

  const handleSuccessfulLogin = async (history: any) => {
    try {
      await Promise.all([equipment.getListOfBorers()]);

      sendBorerHeartbeat({ loginTime: dayjs(), lastUserInteractionTime: dayjs() });

      history.push('/home');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      id="app-root"
      style={{ touchAction: 'none' }}
      onTouchStart={e => {
        e.preventDefault();
      }}
    >
      <RXDBProvider db={db?.db}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <ThemeProvider theme={darkTheme}>
            <ColorThemeWrapper darkmode>
              <CssBaseline />
              <CacheUpdateHandler />
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                bottom="76px"
              >
                <PWAUpdate serviceWorkerFile="sw.js" />
                <Router>
                  <BorerHeartbeat />
                  <AppBarController loadingModalOpen={showLoadingModal} />
                  <Switch>
                    <Route path="/initializing" exact>
                      <Loading />
                    </Route>
                    <Route path="/home" exact>
                      <Home />
                    </Route>
                    <Route path="/signatures" exact>
                      <Signatures />
                    </Route>
                    <Route path="/shift-start" exact>
                      <ShiftStart />
                    </Route>
                    <Route path="/area-check" exact>
                      <AreaCheckPage />
                    </Route>
                    <Route path="/add-areacheck" exact>
                      <AddAreaCheck />
                    </Route>
                    <Route path="/add-wfai" exact>
                      <AddWorkFaceAreaInspection />
                    </Route>
                    <Route path="/equipment" exact>
                      <Equipment />
                    </Route>
                    <Route path="/delaysandactivities" exact>
                      <PiSightPage lastSyncTime={lastBorerStateSyncTime} />
                    </Route>
                    <Route path="/advances" exact>
                      <Advances />
                    </Route>
                    <Route path="/shift-end">
                      <ShiftEnd />
                    </Route>
                    <Route path="/login" exact>
                      <Login loginHandler={handleSuccessfulLogin} />
                    </Route>
                    <Route path="/logout" exact>
                      <Logout rxdb={db?.db} />
                    </Route>
                    <Route path="/">
                      <Redirect to="/initializing" />
                    </Route>
                  </Switch>
                  <TabBar />
                </Router>
              </SnackbarProvider>
            </ColorThemeWrapper>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </RXDBProvider>
    </div>
  );
};

export default observer(App);
