import { i18n } from '@nutrien/cxp-components';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Subscription } from 'rxjs';

import { useMst } from '../../mobx-models/Root';
import { BorerActivityDocument } from '../../rxdb/BorerActivity/queryBuilder';
import { useBorerActivity } from '../../rxdb/BorerActivity/useBorerActivity';
import { BorerActivityType } from '../../rxdb/BorerActivityType/queryBuilder';
import { useBorerActivityType } from '../../rxdb/BorerActivityType/useBorerActivityType';
import { useBorerShiftActivityEmployees } from '../../rxdb/BorerShiftActivityEmployees/useBorerShiftActivityEmployees';
import useBorerShiftCrew from '../../rxdb/BorerShiftCrew/useBorerShiftCrew';
import { DelayActivityTypeCategory } from '../../rxdb/DelayActivityTypeCategory/queryBuilder';
import { useDelayActivityTypeCategory } from '../../rxdb/DelayActivityTypeCategory/useDelayActivityTypeCategory';
import { FormField } from '../../rxdb/Employees/formHelper';
import { EmployeeDocument } from '../../rxdb/Employees/queryBuilder';
import { useEmployees } from '../../rxdb/Employees/useEmployees';
import { useDateFormatters, useNotification } from '../../utilities';
import { getShiftCorrectedTime } from '../../utilities/shiftTimeUtilities';
import { translate } from '../../utilities/siteLanguageTranslation/siteLanguageTranslation';
import { uniq } from '../../utilities/sortHelper';
import { TIME_FORMAT, USER_TIMEZONE } from '../../utilities/useDateFormatters';
import AddBorerActivityCard from '../AddBorerActivityCard/AddBorerActivityCard';
import GenericSidePanel from '../GenericSidePanel';
import { IAppointment } from '../pages/DelaysAndActivitiesV2/DelaysSchedulerView/ActivitiesSchedulerHelpers';

dayjs.extend(timezone);

interface Props {
  open: boolean;
  onClose: (newDelayId?: string) => void;
  onOpen: () => void;
  onCancel?: () => void;
  prevAppointment?: IAppointment | null;
}

const AddBorerActivitySidePanel = ({ open, onClose, onOpen, onCancel, prevAppointment }: Props) => {
  const { shiftPicker } = useMst();
  const { setBorerActivity } = useBorerActivity();
  const { allDelayTypeCategories } = useDelayActivityTypeCategory();
  const { activityTypesForShift } = useBorerActivityType();
  const {
    borerShiftActivityEmployeesInitialized,
    borerShiftActivityEmployeeCollection,
    listBorerShiftActivityEmployees,
    setEmployeesOnBorerShiftActivity,
  } = useBorerShiftActivityEmployees();
  const { employeesList: employees } = useEmployees({
    isActive: true,
    onlyConstructionAndProduction: false,
    populateCrew: true,
    populatedPosition: true,
    onlyActiveCrew: false,
  });
  const { getBorerShiftCrews, borerShiftCrewCollection } = useBorerShiftCrew();

  const { stripSecondsAndMilliseconds } = useDateFormatters();
  const { successNotification, errorNotification } = useNotification();

  // BorerDelay state
  const [startTime, setStartTime] = useState<Dayjs>(stripSecondsAndMilliseconds(dayjs()));
  const [endTime, setEndTime] = useState<Dayjs>(
    stripSecondsAndMilliseconds(dayjs().add(30, 'minute')),
  );
  const [startErrorText, setStartErrorText] = useState<string>('');

  const emptyCompletedBy = [
    {
      value: undefined,
      initialValue: undefined,
      hasEdits: false,
      error: undefined,
    },
  ];

  const [category, setCategory] = useState<DelayActivityTypeCategory>();
  const [activityType, setActivityType] = useState<BorerActivityType>();
  const [comment, setComment] = useState<string>();
  const [faceChangeCrew, setFaceChangeCrew] = useState<boolean>(false);

  // collections
  const [activityTypes, setActivityTypes] = useState<BorerActivityType[]>([]);
  const [activityEmployees, setActivityEmployees] = useState<EmployeeDocument[]>([]);
  const [completedByFields, setCompletedByFields] =
    useState<FormField<EmployeeDocument | undefined>[]>(emptyCompletedBy);
  const [faceChangeCrewAllowed, setFaceChangeCrewAllowed] = useState<boolean>(false);

  // subscriptions
  const [activityEmployeesSubscription, setActivityEmployeesSubscription] =
    useState<Subscription>();

  // Side Panel Controls
  const [fieldsEdited, setFieldsEdited] = useState({});
  const [canSave, setCanSave] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [undo, setUndo] = useState(false);

  const hasEdits = useMemo(() => {
    return !Object.values(fieldsEdited).every(editedFields => !editedFields);
  }, [fieldsEdited]);

  useEffect(() => {
    return () => {
      if (activityEmployeesSubscription) {
        activityEmployeesSubscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (open === true && undo === false) {
      setCanSave(false);
      setIsSaving(false);
      setActivityTypes([]);
      setFieldsEdited({});
      setStartTime(
        prevAppointment
          ? dayjs.tz(prevAppointment.startDate, USER_TIMEZONE)
          : stripSecondsAndMilliseconds(dayjs().tz()),
      );
      setEndTime(
        prevAppointment
          ? dayjs.tz(prevAppointment.endDate, USER_TIMEZONE)
          : stripSecondsAndMilliseconds(dayjs().tz().add(30, 'minute')),
      );
      setActivityType(undefined);
      setCategory(undefined);
      setComment(prevAppointment?.comment || '');

      if (!prevAppointment) {
        setCompletedByFields(emptyCompletedBy);
      }

      setFaceChangeCrew(prevAppointment?.faceChangeCrew);
    }
    if (open === true) {
      setUndo(false);
    }
  }, [open]);

  const getBorerActivityTypes = async () => {
    try {
      setActivityTypes(activityTypesForShift);
      if (prevAppointment && open === true && undo === false) {
        const prevActivityType = activityTypesForShift.find(
          x => x.id === prevAppointment.delayOrActivityTypeId,
        );
        setActivityType(prevActivityType);
        if (prevActivityType) {
          setCategory(
            allDelayTypeCategories.find(x => x.id === prevActivityType.delayActivityTypeCategory),
          );
        }
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: AddBorerActivitySidePanel.tsx ~ line 179 ~ getBorerActivityTypes ~ error',
        error,
      );
    }
  };

  const getBorerShiftActivityEmployees = async () => {
    try {
      const result = await listBorerShiftActivityEmployees(prevAppointment?.id);
      setActivityEmployees(result);
      return result;
    } catch (error) {
      console.log(
        '🚀 ~ file: AddBorerActivitySidePanel.tsx ~ line 197 ~ getBorerShiftActivityEmployees ~ error',
        error,
      );
    }
  };

  useEffect(() => {
    getBorerActivityTypes();
  }, [open, activityTypesForShift]);

  const setupActivityEmployees = async () => {
    const shiftActivityEmployees = await getBorerShiftActivityEmployees();
    if (!shiftActivityEmployees) return;

    const mapped = shiftActivityEmployees.map(x => {
      return {
        value: x,
        initialValue: x,
        hasEdits: false,
        error: undefined,
      };
    });

    setCompletedByFields(prevAppointment ? mapped : emptyCompletedBy);
  };

  useEffect(() => {
    if (
      !undo &&
      open &&
      borerShiftActivityEmployeesInitialized &&
      borerShiftActivityEmployeeCollection
    ) {
      setupActivityEmployees();
      const activityEmployeesSub = borerShiftActivityEmployeeCollection.$.subscribe(() => {
        getBorerShiftActivityEmployees();
      });
      setActivityEmployeesSubscription(activityEmployeesSub);
    }
  }, [open, borerShiftActivityEmployeesInitialized]);

  useEffect(() => {
    // note: 'Face change crew' checkbox is disabled when less than 2 crews setup for the current borer shift
    async function fetchCrews() {
      const { borerShiftCrews } = await getBorerShiftCrews(
        shiftPicker.currentBorerShiftId || undefined,
      );

      setFaceChangeCrewAllowed(borerShiftCrews !== null && borerShiftCrews.length > 1);
    }
    fetchCrews();
  }, [borerShiftCrewCollection]);

  const onSetUndo = (value: boolean) => {
    setUndo(value);
  };

  const onStartTimeChanged = (date: Dayjs) => {
    const adjustedDate = getShiftCorrectedTime(date, shiftPicker.Date, shiftPicker.Type);
    setFieldsEdited(prev => {
      return {
        ...prev,
        ...{
          startTime:
            dayjs.tz(prevAppointment?.startDate, USER_TIMEZONE).toISOString() !==
            dayjs.tz(date, USER_TIMEZONE).toISOString(),
        },
      };
    });

    setStartTime(adjustedDate);
  };

  const onEndTimeChanged = (date: Dayjs) => {
    const adjustedDate = getShiftCorrectedTime(date, shiftPicker.Date, shiftPicker.Type);
    setFieldsEdited(prev => {
      return {
        ...prev,
        ...{
          endDate:
            dayjs.tz(prevAppointment?.endDate, USER_TIMEZONE).toISOString() !==
            dayjs.tz(date, USER_TIMEZONE).toISOString(),
        },
      };
    });
    setEndTime(adjustedDate);
  };

  const onCategorySelected = (cat: DelayActivityTypeCategory) => {
    let categoryId: DelayActivityTypeCategory | undefined;
    const prevActivityType = activityTypesForShift.find(
      x => x.id === prevAppointment?.delayOrActivityTypeId,
    );

    if (prevActivityType) {
      categoryId = allDelayTypeCategories.find(
        x => x.id === prevActivityType.delayActivityTypeCategory,
      );
    }

    setFieldsEdited(prev => {
      return {
        ...prev,
        ...{
          categoryId: categoryId?.id !== cat?.id,
        },
      };
    });
    setCategory(cat);
  };

  const onDelayTypeSelected = (borerActivityType?: BorerActivityType) => {
    setFieldsEdited(prev => {
      return {
        ...prev,
        ...{
          borerActivityType: prevAppointment?.delayOrActivityTypeId !== borerActivityType?.id,
        },
      };
    });
    setActivityType(borerActivityType);
  };

  const onCommentChanged = (val: string) => {
    setFieldsEdited(prev => {
      return {
        ...prev,
        ...{
          comment: prevAppointment?.comment !== val,
        },
      };
    });
    setComment(val);
  };

  const createNewCompletedByField = () => {
    setFieldsEdited(prev => {
      return {
        ...prev,
        ...{
          completedByFields: true,
        },
      };
    });
    const newCompletedByFields = [...completedByFields];
    newCompletedByFields.push({
      value: undefined,
      initialValue: undefined,
      hasEdits: false,
      error: undefined,
    });
    setCompletedByFields(newCompletedByFields);
  };

  const updateSelectedCompletedByField = (index: number, emp: EmployeeDocument) => {
    const existingEmpFullName = `${completedByFields[index].initialValue?.get(
      'firstName',
    )} ${completedByFields[index].initialValue?.get('lastName')}`;
    const currentEmpFullName = `${emp.firstName} ${emp?.lastName}`;

    setFieldsEdited(prev => {
      return {
        ...prev,
        ...{
          emp: existingEmpFullName !== currentEmpFullName,
        },
      };
    });
    const newCompletedByFields = [...completedByFields];
    newCompletedByFields[index].value = emp;
    newCompletedByFields[index].hasEdits = existingEmpFullName !== currentEmpFullName;
    setCompletedByFields(newCompletedByFields);
  };

  const removeCompletedByField = (index: number) => {
    setFieldsEdited(prev => {
      return {
        ...prev,
        ...{
          newCompletedByFields: true,
        },
      };
    });
    const newCompletedByFields = [...completedByFields];
    newCompletedByFields.splice(index, 1);

    setCompletedByFields(newCompletedByFields);
  };

  const onFaceChangeCrewChanged = (val: boolean) => {
    setFieldsEdited(prev => {
      return {
        ...prev,
        ...{
          faceChangeCrew: prevAppointment?.faceChangeCrew !== val,
        },
      };
    });
    setFaceChangeCrew(val);
  };

  const validTimeForFaceChangeCrew = async (): string | undefined => {
    const { borerShiftCrews } = await getBorerShiftCrews(
      shiftPicker.currentBorerShiftId || undefined,
    );

    if (borerShiftCrews) {
      const borerShiftCrew =
        faceChangeCrew && borerShiftCrews && borerShiftCrews.length > 1
          ? borerShiftCrews.find(x => x.crewNumber === 2)
          : borerShiftCrews.find(x => x.crewNumber === 1);

      if (borerShiftCrew) {
        const crewStart = dayjs.tz(borerShiftCrew?.start, USER_TIMEZONE);
        const crewEnd = dayjs.tz(borerShiftCrew?.end, USER_TIMEZONE);

        if (startTime.isBefore(crewStart) || endTime.isAfter(crewEnd)) {
          return `Must be between ${crewStart.format(TIME_FORMAT)} and ${crewEnd.format(
            TIME_FORMAT,
          )}`;
        }
      }
    }
    if (!borerShiftCrews || borerShiftCrews.length === 0) {
      return 'No borer shift crews exist for this shift';
    }
  };

  // Input Validation
  const validateSave = useCallback(async () => {
    setStartErrorText('');
    let validSave = true;
    let timeError = '';

    if (!startTime) validSave = false;
    if (!endTime) validSave = false;
    if (startTime.isAfter(endTime) || startTime.isSame(endTime)) {
      timeError = i18n.t('Start time must be before the end time');
      validSave = false;
    }

    if (endTime.diff(startTime, 'minute') < 5) {
      timeError = `${translate('Activity')} must be 5 minutes or greater`;
      validSave = false;
    }

    const borerShiftCrewValidationResult = await validTimeForFaceChangeCrew();
    if (borerShiftCrewValidationResult) {
      validSave = false;
      timeError = borerShiftCrewValidationResult;
    }

    if (!activityType) validSave = false;
    if (completedByFields.filter(x => x.value !== undefined).length === 0) validSave = false;

    setStartErrorText(timeError);
    setCanSave(validSave && hasEdits);
  }, [
    startTime,
    endTime,
    category,
    activityType,
    shiftPicker.Type,
    shiftPicker.currentBorerShiftId,
    completedByFields,
    faceChangeCrew,
    hasEdits,
  ]);

  useEffect(() => {
    validateSave();
  }, [validateSave]);

  const onSave = async () => {
    setIsSaving(true);

    let borerActivity: BorerActivityDocument | undefined;
    try {
      borerActivity = await setBorerActivity(
        comment,
        startTime,
        endTime,
        activityType?.id,
        uniq<string>(completedByFields.filter(x => x.value).map(y => y.value?.id || '')),
        faceChangeCrew,
        prevAppointment,
      );

      if (borerActivity)
        await setEmployeesOnBorerShiftActivity(
          borerActivity.id,
          uniq<string>(completedByFields.filter(x => x.value).map(y => y.value?.id || '')),
        );
    } catch (error) {
      setIsSaving(false);
      console.log('🚀 ~ file: AddBorerActivitySidePanel.tsx ~ line 365 ~ onSave ~ error', error);
      errorNotification('Shift activity could not be saved. Please try again.');
      return;
    }

    successNotification(
      prevAppointment ? i18n.t('Shift activity updated') : i18n.t('Shift activity added'),
    );
    onClose();
  };

  if (!open) return null;
  return (
    <>
      <GenericSidePanel
        open={open}
        onClose={onClose}
        title={
          prevAppointment
            ? `Edit ${translate('shift activity')}`
            : `Add ${translate('shift activity')}`
        }
        onOpen={onOpen}
        hasEdits={hasEdits}
        canSave={canSave}
        isSaving={isSaving}
        setUndo={onSetUndo}
        onSave={onSave}
        onCancel={onCancel}
        discardNotificationText="Shift activity draft discarded"
      >
        {allDelayTypeCategories.length > 0 && activityTypes.length > 0 && (
          <AddBorerActivityCard
            startTime={startTime}
            onStartTimeChanged={onStartTimeChanged}
            startErrorText={startErrorText}
            endTime={endTime}
            onEndTimeChanged={onEndTimeChanged}
            category={category}
            onCategorySelected={onCategorySelected}
            activityType={activityType}
            onActivityTypeSelected={onDelayTypeSelected}
            comment={comment}
            onCommentChanged={onCommentChanged}
            completedBy={activityEmployees}
            completedByFields={completedByFields}
            handleAddCompletedByField={createNewCompletedByField}
            handleUpdateSelectedEmployeeField={updateSelectedCompletedByField}
            onRemoveCompletedByField={removeCompletedByField}
            faceChangeCrew={faceChangeCrew}
            onFaceChangeCrewChanged={onFaceChangeCrewChanged}
            categories={allDelayTypeCategories}
            activityTypes={activityTypes}
            allEmployees={employees}
            faceChangeCrewAllowed={faceChangeCrewAllowed}
          />
        )}
      </GenericSidePanel>
    </>
  );
};

export default observer(AddBorerActivitySidePanel);
