import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { useMst } from '../../mobx-models/Root';
import { RxdbCollectionName } from '../rxdbCollectionName';
import useShifts from '../Shifts/useShifts';
import { GroundHazardCollection, GroundHazardDocument } from './queryBuilder';

interface UseGroundHazardOptions {
  startTimeUnix?: number;
  endUnixTime?: number;
  overrideHazardsToShowArray?: string[];
}

// Document Scenarios
// 1. overrideHazardsToShowArray array of ids to grab
// 2. has current shift available = just show non remediated with an createdOn < endTimeUnix
// 3. has no current shift available = show all non remediated where createdOn >= startTimeUnix

export const useGroundHazards = ({
  startTimeUnix,
  endUnixTime,
  overrideHazardsToShowArray,
}: UseGroundHazardOptions) => {
  const groundHazardCollection: GroundHazardCollection = useRxCollection(
    RxdbCollectionName.GROUND_HAZARDS,
  );
  const [activeGroundHazards, setActiveGroundHazards] = useState<GroundHazardDocument[]>();
  const { equipment } = useMst();
  const { currentActiveShiftInDB, viewingMostRecentAvailableShift } = useShifts();

  useEffect(() => {
    let subscription: Subscription | undefined;
    setActiveGroundHazards([]);

    const hasCurrentActiveShiftData = !!currentActiveShiftInDB;

    // If overriding hazards, return those
    if (groundHazardCollection && groundHazardCollection !== null && overrideHazardsToShowArray) {
      const findSelector = {
        selector: {
          id: {
            $in: overrideHazardsToShowArray,
          },
        },
      };

      subscription = groundHazardCollection.find(findSelector).$.subscribe(function (gcs) {
        setActiveGroundHazards(gcs);
      });
    } else if (!!groundHazardCollection && !hasCurrentActiveShiftData && startTimeUnix) {
      const findSelector = {
        selector: {
          remediatedOn: { $eq: null },
          borerEquipmentId: { $eq: equipment.selectedBorerId },
        },
      };

      subscription = groundHazardCollection.find(findSelector).$.subscribe(function (gcs) {
        const filteredGcs = gcs.filter(x => x.createdOnUnix >= startTimeUnix);
        setActiveGroundHazards(filteredGcs);
      });
    } else if (
      // If not overriding, return that are active before provided time
      !!groundHazardCollection &&
      endUnixTime
    ) {
      const findSelector = {
        selector: {
          remediatedOn: { $eq: null },
          borerEquipmentId: { $eq: equipment.selectedBorerId },
        },
      };

      subscription = groundHazardCollection.find(findSelector).$.subscribe(function (gcs) {
        const filteredGcs = gcs.filter(
          x => x.createdOnUnix < endUnixTime || viewingMostRecentAvailableShift,
        );
        setActiveGroundHazards(filteredGcs);
      });
    } else {
      setActiveGroundHazards([]);
    }
    return () => {
      if (subscription && subscription.unsubscribe) subscription.unsubscribe();
    };
  }, [
    equipment.selectedBorerId,
    endUnixTime,
    groundHazardCollection,
    overrideHazardsToShowArray,
    currentActiveShiftInDB,
    startTimeUnix,
    viewingMostRecentAvailableShift,
  ]);

  return {
    activeGroundHazards,
  };
};

export default useGroundHazards;
