import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AutoComplete,
  Checkbox,
  CustomPalette,
  ExpansionPanel,
  FormControlLabel,
  i18n,
  Select,
  TextField,
} from '@nutrien/cxp-components';
import { translate, useSiteFeatures } from '@nutrien/minesight-utility-module';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { OptionType } from '../../rxdb/Panels/usePanels';
import { PassDocument } from '../../rxdb/Passes/queryBuilder';
import { SequenceDocument } from '../../rxdb/Sequences/queryBuilder';
import useSite from '../../rxdb/Site/useSite';
import { SurveyPointDocument } from '../../rxdb/SurveyPoints/queryBuilder';
import { HazardPanelTypes } from '../../utilities';
import { MiningMethod } from '../../utilities/constants';
import { sortAlphabeticallyByProperty, sortRoomOptions } from '../../utilities/sortHelper';

const useStyles = makeStyles(() =>
  createStyles({
    panel: {
      backgroundColor: `${CustomPalette.elevation.dp1} !important`,
    },
    indent: {
      paddingLeft: '7px',
    },
    checkboxes: {
      paddingTop: '25px',
    },
    container: {
      width: '100%',
    },
    checkboxLabel: {
      width: '100%',
    },
    statusSelect: {
      marginBottom: '5px',
    },
  }),
);

export enum HazardStatus {
  ACTIVE = 'Active',
  REMEDIATED = 'Remediated',
}

interface Props {
  hasGroundControls: boolean;
  isFullyRemediated?: boolean;
  onFullyRemediatedChanged: (val: boolean) => void;
  reportEndLocation?: boolean;
  onReportEndLocationChanged: (val: boolean) => void;
  roomOptions: OptionType[];
  onRoomChanged: (room: OptionType) => void;
  selectedRoom?: OptionType;
  surveyPointOptions: SurveyPointDocument[];
  onSurveyPointChanged: (surveyPoint: SurveyPointDocument) => void;
  selectedSurveyPoint?: SurveyPointDocument;
  sequences: SequenceDocument[];
  selectedSequence?: SequenceDocument;
  onSequenceChanged: (surveyPoint: SequenceDocument) => void;
  passes: PassDocument[];
  selectedPass?: PassDocument;
  onPassChanged: (pass: PassDocument) => void;
  endDistance: string;
  onEndDistanceChanged: (value: string) => void;
  endDistanceErrorText?: string;
  validationIndex: number;
  setValidationIndex: Dispatch<SetStateAction<number>>;
  forceRemediatedHazard?: boolean;
  expanded: boolean;
  onExpanded: (isExpanded: boolean, expandedPanelId: HazardPanelTypes) => void;
  endHazardLandmark: string;
  setEndHazardLandmark: Dispatch<SetStateAction<string>>;
}

const HazardStatusPanel: React.FC<Props> = ({
  hasGroundControls,
  isFullyRemediated,
  onFullyRemediatedChanged,
  reportEndLocation,
  onReportEndLocationChanged,
  roomOptions,
  onRoomChanged,
  selectedRoom,
  surveyPointOptions,
  onSurveyPointChanged,
  selectedSurveyPoint,
  sequences,
  selectedSequence,
  onSequenceChanged,
  passes,
  selectedPass,
  onPassChanged,
  endDistance,
  onEndDistanceChanged,
  endDistanceErrorText,
  validationIndex,
  setValidationIndex,
  forceRemediatedHazard,
  expanded,
  onExpanded,
  endHazardLandmark,
  setEndHazardLandmark,
}: Props) => {
  const classes = useStyles();

  const hazardStatuses = [HazardStatus.ACTIVE, HazardStatus.REMEDIATED];
  const [selectedStatus, setSelectedStatus] = useState<string>(HazardStatus.ACTIVE);

  const [errors, setErrors] = useState({
    room: '',
    sequence: '',
    step: '',
  });
  const { isLanigan, isVanscoy, isCory, isRocanville, isAllan } = useSiteFeatures();
  const { distanceUnitAbbreviation } = useSite();

  useEffect(() => {
    if (hasGroundControls && isFullyRemediated) {
      setSelectedStatus(HazardStatus.REMEDIATED);
    } else if (forceRemediatedHazard) {
      setSelectedStatus(HazardStatus.REMEDIATED);
    }
  }, [hasGroundControls, isFullyRemediated, forceRemediatedHazard]);

  useEffect(() => {
    if (selectedStatus === HazardStatus.REMEDIATED && validationIndex >= 15) {
      if (!selectedRoom) {
        setErrors(prev => ({ ...prev, room: 'Required' }));
      } else {
        setErrors(prev => ({ ...prev, room: '' }));
      }
    }
  }, [selectedStatus, validationIndex, selectedRoom]);

  useEffect(() => {
    if (
      selectedStatus === HazardStatus.REMEDIATED &&
      selectedRoom?.room &&
      surveyPointOptions &&
      surveyPointOptions.length > 0 &&
      validationIndex >= 16
    ) {
      if (!selectedSurveyPoint) {
        setErrors(prev => ({ ...prev, sequence: 'Required' }));
      } else {
        setErrors(prev => ({ ...prev, sequence: '' }));
      }
    } else {
      setErrors(prev => ({ ...prev, sequence: '' }));
    }
  }, [selectedStatus, validationIndex, selectedSurveyPoint, selectedRoom, surveyPointOptions]);

  useEffect(() => {
    if (selectedStatus === HazardStatus.REMEDIATED && validationIndex >= 17) {
      if (!selectedSequence) {
        setErrors(prev => ({ ...prev, step: 'Required' }));
      } else {
        setErrors(prev => ({ ...prev, step: '' }));
      }
    } else {
      setErrors(prev => ({ ...prev, step: '' }));
    }
  }, [selectedStatus, validationIndex, selectedSequence]);

  const onStatusChanged = (value: string) => {
    setSelectedStatus(value);
    onFullyRemediatedChanged(value === HazardStatus.REMEDIATED);
  };

  const handleRoomChange = (value: any) => {
    onRoomChanged(value);
    onSurveyPointChanged(null);
    onSequenceChanged(null);
    onPassChanged(null);
    onEndDistanceChanged('');
    setValidationIndex(prev => (prev > 16 ? prev : 16));
  };

  const handleSurveyPointChange = (value: any) => {
    onSurveyPointChanged(value);
    onSequenceChanged(null);
    onPassChanged(null);
    onEndDistanceChanged('');
    setValidationIndex(prev => (prev > 17 ? prev : 17));
  };

  const handleSequenceChange = (value: any) => {
    onSequenceChanged(value);
    onPassChanged(null);
    onEndDistanceChanged('');
    setValidationIndex(prev => (prev > 18 ? prev : 18));
  };

  const roomOptionLabel = (option?: OptionType) => {
    if (!option?.panel) return '';

    if (isLanigan)
      return `${option?.block?.description}${option?.panel?.description}${option?.room?.description}`; // In Lanigan we have a BlockPanelRoom string
    if (isRocanville || (isVanscoy && option.panel?.miningMethod === MiningMethod.LONG_ROOM)) {
      return `${option?.panel?.description}-${option?.room?.description}`; // In Vanscoy Long we have a PanelRoom string
    }
    return `${option?.panel?.description}`;
  };

  const inputXSSize = useMemo(() => {
    if (isVanscoy || isRocanville) return 6;
    return 12;
  }, [isRocanville, isVanscoy]);

  return (
    <>
      <ExpansionPanel
        className={classes.panel}
        title={i18n.t('Hazard Status')}
        key="hazard-location"
        data-cy="LocationDetailsPanel"
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded}
        onChange={(event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
          if (isExpanded) {
            setValidationIndex(prev => (prev > 12 ? prev : 12));
            onExpanded(true, HazardPanelTypes.REMEDIATION);
          } else {
            setValidationIndex(prev => (prev > 19 ? prev : 19));
            if (!errors.room && !errors.step && !errors.sequence) {
              onExpanded(false, HazardPanelTypes.REMEDIATION);
            }
          }
        }}
        panelContent={
          <Grid container>
            <Grid item xs={12} className={classes.indent}>
              <Select
                className={classes.statusSelect}
                list={hazardStatuses}
                label={i18n.t('Status')}
                subText={
                  hasGroundControls
                    ? undefined
                    : i18n.t('Ground control required prior to marking remediated.')
                }
                required
                getOptionLabel={(status: string) => {
                  return status;
                }}
                onChange={(event, value) => onStatusChanged(value)}
                value={selectedStatus}
                data-testid="Hazard-Status-AutoComplete"
                disabled={!hasGroundControls || forceRemediatedHazard}
                showSubTextWhenDisabled
              />
            </Grid>

            {((hasGroundControls && selectedStatus === HazardStatus.REMEDIATED) ||
              forceRemediatedHazard) && (
              <Grid item xs={12} className={classes.indent}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(evt: any) => {
                        onReportEndLocationChanged(evt.target.checked);
                      }}
                      name="remediation-complete-checkbox"
                      checked={reportEndLocation}
                    />
                  }
                  label={i18n.t('Report end location')}
                  className={classes.checkboxLabel}
                />
              </Grid>
            )}

            {isFullyRemediated && reportEndLocation && (
              <Grid container className={`${classes.indent} ${classes.checkboxes}`} spacing={2}>
                <Grid item xs={inputXSSize}>
                  <AutoComplete
                    list={sortRoomOptions(roomOptions)}
                    label={isLanigan || isRocanville ? i18n.t('Room') : i18n.t('Panel')}
                    required
                    getOptionLabel={roomOptionLabel}
                    onChange={(event, value) => handleRoomChange(value)}
                    value={selectedRoom}
                    showCaret
                    data-cy="RoomInput"
                    id="end-location-panel-room-auto-complete"
                  />
                </Grid>
                {surveyPointOptions.length > 0 && (
                  <Grid item xs={inputXSSize}>
                    <AutoComplete
                      list={sortAlphabeticallyByProperty(surveyPointOptions, 'description')}
                      label={translate('Sequence')} // V: Section
                      required
                      getOptionLabel={(option: SurveyPointDocument) => {
                        return option.description;
                      }}
                      onChange={(event, value) => handleSurveyPointChange(value)}
                      value={selectedSurveyPoint}
                      showCaret
                      data-cy="SurveyPointInput"
                      id="end-location-survey-point-auto-complete"
                    />
                  </Grid>
                )}
                {selectedRoom && (
                  <>
                    {(isLanigan ||
                      isCory ||
                      isAllan ||
                      (isVanscoy &&
                        selectedRoom?.panel?.miningMethod === MiningMethod.CHEVRON)) && (
                      <Grid item xs={inputXSSize}>
                        <AutoComplete
                          list={sortAlphabeticallyByProperty(sequences, 'description')}
                          label={
                            isLanigan
                              ? i18n.t('Step')
                              : isVanscoy || isAllan
                              ? i18n.t('Sequence')
                              : i18n.t('Sequence (Optional)')
                          }
                          required
                          getOptionLabel={(sequence: SequenceDocument) => sequence.description}
                          onChange={(event, value) => handleSequenceChange(value)}
                          value={selectedSequence}
                          showCaret
                          data-cy="Chevron-Sequence"
                          id="end-location-step-auto-complete"
                        />
                      </Grid>
                    )}
                    <Grid item xs={inputXSSize}>
                      <AutoComplete
                        list={sortAlphabeticallyByProperty(passes, 'description')}
                        label={i18n.t('Pass (optional)')}
                        required
                        getOptionLabel={(pass: any) => {
                          return pass.description;
                        }}
                        onChange={(event, value) => onPassChanged(value)}
                        value={selectedPass}
                        showCaret
                        data-cy="LongRoom-PassInput"
                        autoSelect={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={
                          isCory || isVanscoy || isRocanville
                            ? i18n.t('Distance (optional)')
                            : i18n.t('End distance (optional)')
                        }
                        required
                        unitText={distanceUnitAbbreviation}
                        onChange={event => {
                          onEndDistanceChanged(event.target.value);
                          setValidationIndex(prev => (prev > 19 ? prev : 19));
                        }}
                        value={endDistance}
                        data-cy="EndDistance"
                        inputProps={{ inputMode: 'numeric' }}
                        error={endDistanceErrorText !== ''}
                        errorText={endDistanceErrorText}
                      />
                    </Grid>
                    {isCory && (
                      <Grid item xs={12}>
                        <TextField
                          label={i18n.t('Spad info (optional)')}
                          required
                          onChange={event => setEndHazardLandmark(event.target.value)}
                          value={endHazardLandmark}
                          data-cy="endHazardLandmark-input"
                          id="endHazardLandmark-input"
                          css={undefined}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>
        }
      />
    </>
  );
};

export default observer(HazardStatusPanel);
