import dayjs from 'dayjs';

import { ImageMetadata } from '../components/Thumbnail/Thumbnail';
import { SELECTED_BORER_ID_KEY } from '../mobx-models/Equipment';
import { PopulatedLocation } from '../rxdb/Locations/useLocations';

export const jsonMapReplacer = (key: string, value: any) => {
  if (value instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(value.entries()), // or with spread: value: [...value]
    };
  }
  return value;
};

export const jsonMapReviver = (key: string, value: any) => {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
};

export const dayJsToMinuteUnix = (timeString: string) =>
  dayjs(timeString).set('seconds', 0).set('milliseconds', 0).unix();

export const getBorerShortName = (): string | null => {
  // Get the borers name from local storage
  const borerInfo = localStorage.getItem(SELECTED_BORER_ID_KEY);
  let shortName = null;
  if (borerInfo) {
    const borerInfoObj = JSON.parse(borerInfo);
    shortName = borerInfoObj.shortName;
  }

  return shortName;
};

export const getAttachmentIndex = (
  currentIdx: number,
  existingAttachmentNames: ImageMetadata[],
  currentDate: string,
) => {
  const existingAttachementsByDate: { [key: string]: number[] } = {};
  existingAttachmentNames?.forEach(file => {
    const [attachedDate, attachmentIdx] = file.fileName?.split('.')[0].split('_').slice(-2);
    existingAttachementsByDate[attachedDate] = [
      ...(existingAttachementsByDate[attachedDate] || []),
      Number(attachmentIdx),
    ];
  });
  let attachmentIdx = currentIdx;
  if (existingAttachementsByDate[currentDate]) {
    attachmentIdx = attachmentIdx + Math.max(...existingAttachementsByDate[currentDate]);
  }
  return attachmentIdx;
};

export const convertListOfFilesAndFileListsToArray = (files: File[] | FileList[]) => {
  let filesArray: File[] = [];

  if (files !== null) {
    [...files].forEach(value => {
      if (value instanceof FileList) {
        let numberOfFiles = value.length;
        while (numberOfFiles--) {
          filesArray = [...filesArray, value[numberOfFiles]];
        }
      } else {
        filesArray = [...filesArray, value];
      }
    });
  }

  return filesArray;
};

export const formatFileNamesToBeUnique = (files: File[], prefix = 'image', usePrefix = false) => {
  const unixString = dayjs().unix().toString();
  return files.map(file => {
    const fileBlob = file.slice(0, file.size, file.type) as BlobPart;
    const fileName = !usePrefix ? `${prefix}-${unixString}-${file?.name}` : `${file?.name}`;
    const updatedFile = new File([fileBlob], `${fileName}` as string, {
      type: file?.type,
    });

    return updatedFile;
  });
};

export const getFormattedImages = (
  imagePrefix: string,
  selectedImages: File[] | FileList[],
  existingImageNames: ImageMetadata[] = [],
) => {
  let formattedFiles: File[] = [];

  const fileNamePrefix = imagePrefix.split('/').join('-');
  const date = new Date();
  const currentDate = `${date.getFullYear()}${`0${date.getMonth() + 1}`.slice(
    -2,
  )}${`0${date.getDate()}`.slice(-2)}`;

  if (!selectedImages) return formattedFiles;

  convertListOfFilesAndFileListsToArray(selectedImages).forEach((value, index) => {
    let file: File;

    if (value instanceof FileList) {
      file = value[0];
    } else {
      file = value;
    }

    const fileBlob = file?.slice(0, file.size, file.type) as BlobPart;
    const attachmentIdx = getAttachmentIndex(index + 1, existingImageNames, currentDate);
    const fileName = `${fileNamePrefix}${currentDate}_${attachmentIdx}`;
    const extension = file?.type.split('/')[1];
    const updatedFile = new File([fileBlob], `${fileName}.${extension}` as string, {
      type: file?.type,
    });
    formattedFiles = [...formattedFiles, updatedFile];
  });

  return formattedFiles;
};

export const formatAsStartCase = (str?: string) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getSurveyPointInt = (surveyPointDescription: string) => {
  // Vanscoy survey points have 'S-' prefix
  if (surveyPointDescription.toUpperCase().substring(0, 2) === 'S-') {
    return parseInt(surveyPointDescription.substring(2));
  }
  return parseInt(surveyPointDescription);
};

export const getTimeUntilNextMinute = () =>
  60 * 1000 - ((new Date().getTime() % (60 * 1000)) - 100);

export const locationDifferences = (a: PopulatedLocation, b: PopulatedLocation) => {
  return (
    a.panel?.id !== b.panel?.id ||
    a.pass?.id !== b.pass?.id ||
    a.sequence?.id !== b.sequence?.id ||
    a.surveyPoint?.id !== b.surveyPoint?.id ||
    a.block?.id !== b.block?.id ||
    a.room?.id !== b.room?.id
  );
};

export const sleep = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const capitalizeFirstLetter = (str: string) => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);

  return undefined;
};

export const capitalizeFirstLetterOnly = (str: string) => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  return undefined;
};

export const toCamelCase = (sentence: string) => {
  let out = '';
  sentence.split(' ').forEach((el, idx) => {
    const add = el.toLowerCase();
    out += idx === 0 ? add : add[0].toUpperCase() + add.slice(1);
  });
  return out;
};

export const toKebabCase = (sentence: string) => {
  let out = '';
  sentence.split(' ').forEach((el, idx) => {
    out += `${el.toLowerCase()}${idx === sentence.split(' ').length - 1 ? '' : '-'}`;
  });
  return out;
};

export const compareObjects = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
    return false;
  }

  const keysA = Object.keys(obj1);
  const keysB = Object.keys(obj2);

  if (keysA.length !== keysB.length) {
    return false;
  }

  let result = true;

  keysA.forEach(key => {
    if (!keysB.includes(key)) {
      result = false;
    }

    if (typeof obj1[key] === 'function' || typeof obj2[key] === 'function') {
      if (obj1[key].toString() !== obj2[key].toString()) {
        result = false;
      }
    }

    if (!compareObjects(obj1[key], obj2[key])) {
      result = false;
    }
  });

  return result;
};
