import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import { RxdbCollectionName } from '../rxdbCollectionName';
import { ProductionTargetCollection, ProductionTargetDocument } from './queryBuilder';

export const useProductionTarget = () => {
  const productionTargetCollection: ProductionTargetCollection = useRxCollection(
    RxdbCollectionName.PRODUCTION_TARGET,
  );

  const [productionTargetInitialized, setProductionTargetInitialized] = useState(false);

  useEffect(() => {
    if (productionTargetCollection) setProductionTargetInitialized(true);
  }, [productionTargetCollection]);

  const listProductionTargetForShiftId = async (
    currentBorerShiftId: string,
  ): Promise<ProductionTargetDocument[]> => {
    if (!productionTargetCollection) {
      return [];
    }

    const productionTarget = await productionTargetCollection
      .find({
        selector: {
          borerShiftId: currentBorerShiftId,
        },
      })
      .exec();
    return productionTarget;
  };

  return {
    productionTargetInitialized,
    listProductionTargetForShiftId,
    productionTargetCollection,
  };
};

export default useProductionTarget;
