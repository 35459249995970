import { rootStore } from '../mobx-models/Root';
import { BorerStateType, listBorerStateTypes } from './BorerStateTypeFeed/queryBuilder';

// A class to help augment data during migrations by performing one API call
export default class MigrationHelper {
  private static _instance: MigrationHelper;

  borerStateTypes: BorerStateType[];

  constructor() {
    this.borerStateTypes = [];
    MigrationHelper._instance = this;
  }

  public static get instance(): MigrationHelper {
    return MigrationHelper._instance;
  }

  public static get borerStateTypes(): BorerStateType[] {
    return this.borerStateTypes;
  }

  fetchBorerStateTypes = async () => {
    try {
      if (!rootStore.user.siteId) throw new Error('No siteId found in rootStore.user');
      const stateTypes = await listBorerStateTypes(rootStore.user.siteId);
      this.borerStateTypes = stateTypes.data.listBorerStateTypes;
    } catch (error) {
      console.log('🚀 ~ file: MigrationHelper.ts:47 ~ error', error);
    }
  };

  timeUsageTypeIdForStateTypeId = async (
    stateTypeId: string,
  ): Promise<{ timeUsageTypeId: string | null; isPlanned: boolean | null }> => {
    if (!this.borerStateTypes.length) await this.fetchBorerStateTypes();
    return {
      timeUsageTypeId:
        this.borerStateTypes.find(stateType => stateType.id === stateTypeId)?.timeUsageType?.id ||
        null,
      isPlanned:
        this.borerStateTypes.find(stateType => stateType.id === stateTypeId)?.isPlanned || null,
    };
  };
}
