import type { Theme } from '@material-ui/core';
import { createStyles, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AutoComplete, Button, Dialog, i18n } from '@nutrien/cxp-components';
import React, { useState } from 'react';

import { Borer } from '../../graphql/Equipment';
import { useOnlineStatus } from '../../utilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      marginTop: '20px',
    },
    firstAutoComplete: {
      marginTop: '8px',
    },
    card: {
      padding: '70px 40px',
      borderRadius: '10px',
      backgroundColor: `${theme.palette.background.paper}`,
    },
    imageContainer: {
      marginBottom: '50px',
      textAlign: 'center',
    },
  }),
);

interface Props {
  open: boolean;
  listOfBorers: Borer[];
  assignBorer: (borer: Borer) => void;
}

const BorerSelector: React.FC<Props> = ({ open, listOfBorers, assignBorer }: Props) => {
  const classes = useStyles();
  const env = process.env.REACT_APP_ENV;

  const [selectedBorer, setSelectedBorer] = useState<Borer>();
  const [assignedBorerClicked, setAssignedBorerClicked] = useState<boolean>(false);
  const online = useOnlineStatus();

  const getBorerLabel = (borer: Borer) => {
    const borerName = borer?.shortName ? `Borer ${borer.shortName}` : borer.longName;
    if (env !== 'prod' && borerName) {
      return `${borerName} (${borer.assignedPanel?.miningMethod?.name})`;
    }
    return borerName || '';
  };

  const onBorerChanged = (value: Borer) => {
    setSelectedBorer(value);
  };

  const onAssignBorer = () => {
    if (selectedBorer && online) {
      setAssignedBorerClicked(true);
      assignBorer(selectedBorer);
    }
  };

  return (
    <Dialog
      open={open}
      titleText={i18n.t('iPad assignment')}
      backButton={false}
      disableEnforceFocus
      dialogActions={
        <Grid container spacing={2} justify="flex-end">
          <Grid item xs={4}>
            <Button
              onClick={onAssignBorer}
              variant="contained"
              color="primary"
              noMinHeight
              isLoading={assignedBorerClicked}
              disabled={!selectedBorer || !online}
              id="assign-button"
              data-testid="assign-ipad-button"
            >
              {i18n.t('Assign iPad')}
            </Button>
          </Grid>
        </Grid>
      }
      maxWidth="xs"
      maxHeight="280px"
      BackdropProps={{
        onClick: event => {
          event.preventDefault();
        },
        onTouchStart: event => {
          event.preventDefault();
        },
        style: { touchAction: 'none' },
      }}
    >
      <Grid container>
        {!online && (
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.text}>
              You appear to be offline. Please connect the iPad to WiFi, then select a borer.
            </Typography>
          </Grid>
        )}
        {online && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.text}>
                This iPad is assigned to:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AutoComplete
                list={listOfBorers.sort((a, b) => {
                  return (
                    a?.shortName?.localeCompare(b?.shortName || '') ||
                    a?.longName?.localeCompare(b?.longName)
                  );
                })}
                required
                getOptionLabel={getBorerLabel}
                onChange={(event, value) => {
                  onBorerChanged(value);
                }}
                value={selectedBorer}
                data-cy="Borer-Autocomplete"
                className={classes.firstAutoComplete}
                autoSelect={false}
                noMinHeight
                showCaret
                loading={!listOfBorers || (listOfBorers && listOfBorers.length === 0)}
                data-testid="borer-selector-autocomplete"
              />
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  );
};

export default BorerSelector;
