import { i18n } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { OptionType } from '@/rxdb/Panels/usePanels';

import { useMst } from '../../mobx-models/Root';
import {
  LocationDocument,
  PassDocument,
  SequenceDocument,
  SurveyPointDocument,
} from '../../models/models';
import { BlockDocument } from '../../rxdb/Blocks/queryBuilder';
import useBlocks from '../../rxdb/Blocks/useBlocks';
import useLocations from '../../rxdb/Locations/useLocations';
import { MiningCutSequencePassObj } from '../../rxdb/MiningCut/queryBuilder';
import useMiningCuts from '../../rxdb/MiningCut/useMiningCuts';
import { PanelDocument } from '../../rxdb/Panels/queryBuilder';
import { PredictionDocument } from '../../rxdb/Prediction/queryBuilder';
import usePrediction from '../../rxdb/Prediction/usePrediction';
import useProduction from '../../rxdb/Productions/useProduction';
import { Room } from '../../rxdb/Rooms/queryBuilder';
import useRooms from '../../rxdb/Rooms/useRooms';
import { generateBaseEntity } from '../../rxdb/rxdbUtilityFunctions';
import { MiningMethodAllCap, useNotification } from '../../utilities';
import { FEET_PER_METERS } from '../../utilities/constants';
import GenericSidePanel from '../GenericSidePanel';
import PredictionLocationExpansionPanel from './PredictionLocationExpansionPanel';

export interface LocationPanelData {
  block?: BlockDocument;
  panel?: PanelDocument;
  room?: Room;
  surveyPoint?: SurveyPointDocument;
  sequence?: SequenceDocument;
  pass?: PassDocument;
  miningCutSeqPassObj?: MiningCutSequencePassObj;
  footage?: string;
  errors?: LocationPanelErrors;
}

export interface LocationPanelErrors {
  room: string;
  sequence: string;
  step: string;
  advanceFootage: string;
  advanceEndFootage: string;
}

interface Props {
  open: boolean;
  onClose: (newDelayId?: string) => void;
  onOpen: () => void;
  onCancel?: () => void;
  predictionToEdit?: PredictionDocument;
}

const EditPredictionSidePanel = ({
  open,
  onClose,
  onOpen,
  onCancel,
  predictionToEdit,
}: Props): ReactElement<Props> => {
  const { shiftPicker } = useMst();
  const { setPrediction, createPrediction } = usePrediction();
  const { createLocation, updateLocation, getLocationById } = useLocations();
  const { mostRecentProductionLocationInformation } = useProduction();
  const { miningCutsInitialized, getMiningCutsForMiningMethod } = useMiningCuts();

  const { successNotification, errorNotification } = useNotification();
  const { isLanigan, isVanscoy, isCory } = useSiteFeatures();

  // Side Panel Controls
  const [hasEdits, setHasEdits] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [undo, setUndo] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  // Advance properties
  const [endPanelExpanded, setEndPanelExpanded] = useState<boolean>(true);
  const onSetUndo = (value: boolean) => {
    setUndo(value);
  };

  const [endLocationPanelData, setEndLocationPanelData] = useState<LocationPanelData>();
  const [endLocationPanelInitialData, setEndLocationPanelInitialData] =
    useState<LocationPanelData>();

  const EMPTY_ERRORS = {
    room: '',
    sequence: '',
    step: '',
    advanceFootage: '',
    advanceEndFootage: '',
  };

  const setupCommonData = async (loc: LocationDocument | null) => {
    let footage = '';
    if (predictionToEdit) {
      footage = predictionToEdit.endDistance;
    }

    let room;
    let pass;
    let surveyPoint;
    let sequence;
    let miningCutSequencePassObj;
    let panel;
    let block;
    let mostRecentFootage;
    if (loc) {
      [room, pass, surveyPoint, sequence, panel] = await Promise.all([
        loc.populate('room'),
        loc.populate('pass'),
        loc.populate('surveyPoint'),
        loc.populate('sequence'),
        loc.populate('panel'),
      ]);
      block = await panel?.populate('block');

      const miningCuts = await getMiningCuts({ block, panel, room });
      miningCutSequencePassObj = miningCuts.find(
        x => (sequence === null || x.sequence?.id === sequence.id) && x.pass.id === pass.id,
      );

      if (!miningCutSequencePassObj) {
        ({ miningCutSequencePassObj } = mostRecentProductionLocationInformation);
      }
    } else {
      ({
        room,
        pass,
        surveyPoint,
        sequence,
        miningCutSequencePassObj,
        panel,
        block,
        mostRecentFootage,
      } = mostRecentProductionLocationInformation);
    }

    const locationPanelData: LocationPanelData = {
      block,
      panel,
      room,
      surveyPoint,
      sequence,
      pass,
      miningCutSeqPassObj: miningCutSequencePassObj,
      footage: predictionToEdit
        ? footage?.toString()
        : `${mostRecentFootage || mostRecentFootage === 0 ? mostRecentFootage : ''}`,
      errors: EMPTY_ERRORS,
    };

    setEndLocationPanelData(locationPanelData);
    setEndLocationPanelInitialData(locationPanelData);
  };

  const initLocationData = async () => {
    await setupCommonData(null);
    setLoading(false);
  };

  const initLocationFromPrediction = async () => {
    const loc = await predictionToEdit?.populate('endLocation');
    await setupCommonData(loc);
    setLoading(false);
  };

  const getMiningCuts = async (optionType: OptionType) => {
    const { panel } = optionType;
    if (!panel) return [];

    const miningCutsObjs = await getMiningCutsForMiningMethod(panel.miningMethod);

    return miningCutsObjs;
  };

  const updateEndLocation = (panelData: LocationPanelData) => {
    let isUpdated = false;
    // Validate Sequence (SurveyPoint in BE)
    if (
      (isChevron || isCory) &&
      panelData?.surveyPoint?.id !== endLocationPanelInitialData?.surveyPoint?.id
    ) {
      isUpdated = true;
    }
    if (panelData.pass?.id !== endLocationPanelInitialData?.pass?.id) {
      isUpdated = true;
    }
    //panel
    if (panelData.panel?.id !== endLocationPanelInitialData?.panel?.id) {
      isUpdated = true;
    }
    //footage - distance
    if (panelData.footage !== endLocationPanelInitialData?.footage) {
      isUpdated = true;
    }
    setHasEdits(isUpdated);
    setEndLocationPanelData({
      ...panelData,
    });
  };

  const isChevron = useMemo(() => {
    return endLocationPanelData?.panel?.miningMethod.toUpperCase() === MiningMethodAllCap.CHEVRON;
  }, [endLocationPanelData?.panel]);
  // For Cory / Vans-Chevron
  const { defaultRoomForPanel: defaultEndRoom } = useRooms(endLocationPanelData?.panel?.id);
  const { defaultBlock } = useBlocks();

  useEffect(() => {
    if (isCory || (isVanscoy && isChevron)) {
      if (defaultEndRoom?.id && defaultBlock?.id) {
        setEndLocationPanelData(prev => ({
          ...prev,
          room: defaultEndRoom,
          block: defaultBlock,
        }));
      }
    }
  }, [defaultEndRoom, defaultBlock, isCory, isVanscoy, isChevron]);

  const canSave = useMemo(() => {
    if (isLanigan && !endLocationPanelData?.room) return false;
    if (!endLocationPanelData?.panel) return false;
    if (!endLocationPanelData?.miningCutSeqPassObj) return false;
    if (
      endLocationPanelData?.footage === '' ||
      endLocationPanelData?.errors?.advanceFootage !== '' ||
      endLocationPanelData?.errors?.advanceEndFootage !== ''
    )
      return false;
    if (endLocationPanelData?.errors?.room !== '') return false;
    if (endLocationPanelData?.errors?.sequence !== '') return false;
    if (endLocationPanelData?.errors?.step !== '') return false;

    if (!hasEdits) return false;

    return true;
  }, [hasEdits, endLocationPanelData]);

  // Save
  const onSave = async () => {
    setIsSaving(true);
    const endMeters = parseFloat(endLocationPanelData?.footage) / FEET_PER_METERS;

    if (!endLocationPanelData?.footage) throw new Error('Missing endLocation data');
    // update the Prediction
    try {
      const endLocation =
        predictionToEdit && predictionToEdit.endLocation
          ? await updateExistingLocation(
              predictionToEdit.endLocation,
              endMeters,
              endLocationPanelData,
            )
          : await createNewLocation(endMeters, endLocationPanelData);

      let doc;
      if (!predictionToEdit) {
        doc = {
          ...generateBaseEntity(),
          borerShiftId: shiftPicker.currentBorerShiftId,
          endLocation: endLocation?.id,
          endDistance: parseFloat(endLocationPanelData?.footage),
        };
      }

      if (predictionToEdit)
        await setPrediction(
          predictionToEdit,
          parseFloat(endLocationPanelData?.footage),
          endLocation?.id,
        );
      else await createPrediction(doc);

      successNotification(
        predictionToEdit ? i18n.t('Prediction updated') : i18n.t('Prediction created'),
      );
      onClose();
    } catch (error) {
      setIsSaving(false);
      console.error(`ERROR ${predictionToEdit ? 'updating' : 'saving'} prediction: ${error}`);
      errorNotification(`Could not ${predictionToEdit ? 'update' : 'save'} the prediction.`);
    }
  };

  const createNewLocation = async (meters: number, panelData: LocationPanelData) => {
    const newLocation = await createLocation(
      panelData.panel?.id,
      panelData.room?.id,
      panelData.surveyPoint?.id,
      panelData.miningCutSeqPassObj?.sequence?.id,
      panelData.miningCutSeqPassObj?.pass?.id,
      meters,
      meters,
    );
    return newLocation;
  };

  const updateExistingLocation = async (
    locationId: string,
    meters: number,
    panelData: LocationPanelData,
  ) => {
    const existingLocation = await getLocationById(locationId);
    const updatedLocation = await updateLocation(
      existingLocation,
      meters,
      panelData.panel?.id,
      panelData.room?.id,
      panelData.surveyPoint?.id,
      panelData.miningCutSeqPassObj?.sequence?.id,
      panelData.miningCutSeqPassObj?.pass?.id,
    );
    return updatedLocation;
  };

  useEffect(() => {
    if (miningCutsInitialized && open && !undo) {
      setLoading(true);

      if (predictionToEdit) {
        initLocationFromPrediction();
      } else {
        initLocationData();
        setHasEdits(true);
      }

      setHasEdits(false);
      setIsSaving(false);
    }
    if (open) {
      setUndo(false);
    }
  }, [open, miningCutsInitialized]);

  return (
    <>
      <GenericSidePanel
        open={open}
        onClose={onClose}
        title={predictionToEdit ? i18n.t("Edit operator's prediction") : i18n.t('Add prediction')}
        onOpen={onOpen}
        hasEdits={hasEdits}
        canSave={canSave}
        isSaving={isSaving}
        setUndo={onSetUndo}
        onSave={onSave}
        onCancel={onCancel}
        discardNotificationText="Operator's prediction draft discarded"
        loading={loading}
      >
        {endLocationPanelData && (
          <PredictionLocationExpansionPanel
            title={i18n.t('End location')}
            expanded={endPanelExpanded}
            onExpanded={expanded => setEndPanelExpanded(expanded)}
            onUpdate={updateEndLocation}
            locationPanelData={endLocationPanelData}
          />
        )}
      </GenericSidePanel>
    </>
  );
};

export default EditPredictionSidePanel;
