import type { Theme } from '@material-ui/core';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { useSiteFeatures } from '@nutrien/minesight-utility-module';
import React, { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';

import { useMst } from '../../mobx-models/Root';
import useProduction from '../../rxdb/Productions/useProduction';
import { useNumberFormatter } from '../../utilities/useNumberFormatter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      margin: '4px 4px 10px 4px !important',
      padding: '16px',
      boxShadow: 'none !important',
    },
    label: {
      color: theme.palette.text.secondary,
    },
  }),
);

const ShiftEndProductionCard: React.FC = () => {
  const classes = useStyles();
  const { shiftPicker } = useMst();
  const {
    productionInitialized,
    advancesInitialized,
    advancesCollection,
    getActualTonnesAndTotalAdvance,
  } = useProduction();
  const { format } = useNumberFormatter();
  const { distanceUnitAbbreviation } = useSiteFeatures();

  const [actualTonnes, setActualTonnes] = useState<string>();
  const [totalAdvance, setTotalAdvance] = useState<string>();
  const [advancesSubscription, setAdvancesSubscription] = useState<Subscription>();

  useEffect(() => {
    return function cleanup() {
      if (advancesSubscription) advancesSubscription.unsubscribe();
    };
  });

  const calculateActualTonnes = async () => {
    const actual = await getActualTonnesAndTotalAdvance();
    setActualTonnes(format(actual.actualTotalTonnes));
    setTotalAdvance(format(actual.actualTotalAdvance));
  };

  const subscribeToAdvances = async () => {
    if (advancesInitialized && advancesCollection) {
      const subscription = advancesCollection.$.subscribe(async () => {
        await calculateActualTonnes();
      });
      setAdvancesSubscription(subscription);
    }
  };

  const setup = async () => {
    await calculateActualTonnes();
    subscribeToAdvances();
  };

  useEffect(() => {
    if (productionInitialized && advancesInitialized && shiftPicker?.currentBorerShiftId) {
      setup();
    }
  }, [productionInitialized, advancesInitialized, shiftPicker?.currentBorerShiftId]);

  return (
    <Card className={classes.cardRoot} onClick={() => {}}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Typography variant="h5">{i18n.t('Advance')}</Typography>
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={4}>
          <Typography variant="body2" className={classes.label}>
            {i18n.t('Total tonnes')}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          {actualTonnes} t
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" className={classes.label}>
            {i18n.t('Total advance')}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">
            {totalAdvance} {distanceUnitAbbreviation}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ShiftEndProductionCard;
