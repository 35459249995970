import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AutoComplete, darkTheme, GroupedAutoComplete, i18n } from '@nutrien/cxp-components';
import { translate } from '@nutrien/minesight-utility-module';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import { PopulatedLocation } from '../../rxdb/Locations/useLocations';
import useMiningCuts from '../../rxdb/MiningCut/useMiningCuts';
import usePanels, { OptionType } from '../../rxdb/Panels/usePanels';
import { SurveyPointDocument } from '../../rxdb/SurveyPoints/queryBuilder';
import useSurveyPoints from '../../rxdb/SurveyPoints/useSurveyPoints';

const useStyles = makeStyles(() =>
  createStyles({
    itemSpacing: {
      padding: '0px 5px',
    },
    topMargin: {
      marginTop: '16px',
    },
    textFieldSpacer: {
      padding: '0px 5px',
    },
    footageText: {
      color: darkTheme.palette.common.white,
      opacity: 0.5,
      fontWeight: 600,
    },
  }),
);

interface Props {
  location: PopulatedLocation;
  onLocationChange: (key: keyof PopulatedLocation, value: any) => void;
  isLanigan?: boolean;
  isCory?: boolean;
  isVanscoy?: boolean;
  isRocanville?: boolean;
  isAllan?: boolean;
  direction?: 'row' | 'column';
  isChevron?: boolean;
  setHasValidationErrors: Dispatch<SetStateAction<boolean>>;
  viewOnly?: boolean;
}

const GenericLocationComponent: React.FC<Props> = ({
  direction = 'row',
  isLanigan = false,
  isCory = false,
  isVanscoy = false,
  isChevron = false,
  viewOnly = false,
  isRocanville = false,
  isAllan = false,
  setHasValidationErrors,
  location,
  onLocationChange,
}: Props) => {
  const [errors, setErrors] = useState({
    room: '',
    sequence: '',
    step: '',
    advanceFootage: '',
    advanceStartFootage: '',
    advanceEndFootage: '',
  });

  const classes = useStyles();
  const { getMiningCutLabel } = useMiningCuts();
  const { currentPanel, miningMethod } = useCurrentBorer();
  const { augmentedPanelList, panelList, roomList, panelListLoaded, roomListLoaded } = usePanels(
    currentPanel?.miningMethod,
    miningMethod,
  );

  const {
    surveyPointsForPanel,
    surveyPointsForPanelLoaded,
    surveyPointsForRoom,
    surveyPointsForRoomLoaded,
  } = useSurveyPoints(location.panel?.id, location.room?.id);

  const autoCompletePanelList = useMemo(() => {
    if ((isChevron || isCory || isAllan) && panelList) return panelList;
    if (!isChevron && augmentedPanelList) return augmentedPanelList;
    return [];
  }, [isChevron, panelList, augmentedPanelList, isCory, isAllan]);

  const getPanelLabel = useCallback(
    (option: OptionType) => {
      if (isChevron || isCory) {
        return option?.panel?.description || '';
      }
      if (option?.panel?.description && option.room?.description)
        return `${option?.panel?.description}-${option.room.description}`;
      if (option.room?.description) return option.room.description;
      return '';
    },
    [isChevron, isCory],
  );
  const getRoomLabel = useCallback((option: OptionType) => {
    if (option === '') return '';
    return option?.room?.description.includes('PE')
      ? `${option?.room?.description || ''}${option?.block?.description || ''}${
          option?.panel?.description || ''
        }`
      : `${option?.block?.description || ''}${option?.panel?.description || ''}${
          option?.room?.description || ''
        }`;
  }, []);

  // For Lanigan/Vanscoy - Chevron - Use Panel MiningMethod
  // For Lanigan/Vanscoy - LongRoom - Use room (Section) miningMethod
  // For Cory - ZA & ZB - Use room (breakthrough) miningMethod
  const {
    miningCutListForMiningMethod,
    miningCutListForMiningPattern,
    miningCutsForMiningPatternLoaded,
    miningCutsForMiningMethodLoaded,
  } = useMiningCuts(
    isChevron ? location.surveyPoint?.miningPattern : location.room?.miningPattern,
    location.panel?.miningMethod,
  );

  const passList = useMemo(() => {
    if (isLanigan || isVanscoy) return miningCutListForMiningPattern;
    if ((isCory || isAllan) && isChevron) return miningCutListForMiningPattern;
    if (isCory || isRocanville) return miningCutListForMiningMethod;
    return [];
  }, [
    isVanscoy,
    isLanigan,
    isChevron,
    isCory,
    isAllan,
    isRocanville,
    miningCutListForMiningMethod,
    miningCutListForMiningPattern,
  ]);

  const sequenceValidation = useCallback(() => {
    if (surveyPointsForPanelLoaded && surveyPointsForRoomLoaded) {
      if (!isLanigan && !surveyPointsForPanel.length)
        return `No ${translate('sequence')}'s exist for ${isLanigan ? 'room' : 'panel'}`;
      if (isLanigan && !surveyPointsForRoom.length)
        return `No ${translate('sequence')}'s exist for ${isLanigan ? 'room' : 'panel'}`;
    }
    if (surveyPointsForPanelLoaded && surveyPointsForRoomLoaded) {
      if (!location.surveyPoint) return i18n.t('Required');
    }
    return '';
  }, [
    surveyPointsForPanelLoaded,
    surveyPointsForRoomLoaded,
    surveyPointsForPanel,
    location.surveyPoint,
    surveyPointsForRoom.length,
    isLanigan,
  ]);

  const stepValidation = useCallback(() => {
    if (miningCutsForMiningPatternLoaded && miningCutsForMiningMethodLoaded) {
      if (!passList.length)
        return `No passes exist for ${isChevron ? translate('sequence') : 'Panel'}`;
      if (location.panel && !passList.length) {
        return `No passes exist for ${isChevron ? translate('sequence') : 'panel'}`;
      }
      if (!location.pass && !location.sequence) {
        return i18n.t('Required');
      }
    }
    return '';
  }, [
    miningCutsForMiningPatternLoaded,
    miningCutsForMiningMethodLoaded,
    location.panel,
    location.pass,
    location.sequence,
    passList.length,
    isChevron,
  ]);

  useEffect(() => {
    const errorObj = {
      room: !location.room?.id ? i18n.t('Required') : '',
      sequence: isChevron && location?.panel?.id ? sequenceValidation() : null,
      step: location?.surveyPoint?.id ? stepValidation() : null,
    };
    setHasValidationErrors(!!Object.values(errorObj).find(error => error));
    setErrors(prev => ({
      ...prev,
      ...errorObj,
    }));
  }, [stepValidation, sequenceValidation, isChevron, location]);

  const inputSpacing = direction === 'row' ? 4 : 12;

  const onRoomOrPanelChange = useCallback((event, panelObj) => {
    onLocationChange('panel', panelObj?.panel);
    onLocationChange('room', panelObj?.room);
    onLocationChange('block', panelObj?.block);
    onLocationChange('surveyPoint', undefined);
    onLocationChange('sequence', undefined);
    onLocationChange('pass', undefined);
  }, []);

  return (
    <>
      <Grid container className={classes.topMargin} direction={direction}>
        {isLanigan && (
          <Grid item xs={inputSpacing} className={classes.itemSpacing}>
            <AutoComplete
              autoSelect={false}
              list={roomList}
              label={i18n.t('Room')}
              getOptionLabel={getRoomLabel}
              onChange={onRoomOrPanelChange}
              value={
                location.block && location.panel && location.room
                  ? { block: location.block, panel: location.panel, room: location.room }
                  : undefined
              }
              showCaret
              data-cy="LaniganRoom-Input"
              error={errors.room !== ''}
              errorText={errors.room}
              disabled={viewOnly}
              loading={!roomListLoaded}
              data-testid="location-room-auto-complete"
            />
          </Grid>
        )}
        {!isLanigan && (
          <Grid item xs={inputSpacing} className={classes.itemSpacing}>
            <AutoComplete
              autoSelect={false}
              list={autoCompletePanelList}
              label={isRocanville ? i18n.t('Room') : i18n.t('Panel')}
              getOptionLabel={getPanelLabel}
              onChange={onRoomOrPanelChange}
              value={{ block: location.block, panel: location.panel, room: location.room }}
              showCaret
              data-cy="CoryVanscoyRoom-Input"
              error={errors.room !== ''}
              errorText={errors.room}
              disabled={viewOnly}
              loading={!panelListLoaded}
              data-testid="location-room-auto-complete"
            />
          </Grid>
        )}
        {(isChevron || isCory) && (
          <Grid item xs={inputSpacing} className={classes.itemSpacing}>
            <AutoComplete
              autoSelect={false}
              list={isLanigan ? surveyPointsForRoom : surveyPointsForPanel}
              label={translate(i18n.t('Sequence'))}
              getOptionLabel={(option: SurveyPointDocument) => {
                return option.description;
              }}
              onChange={(event, value) => {
                onLocationChange('surveyPoint', value);
                onLocationChange('sequence', undefined);
                onLocationChange('pass', undefined);
              }}
              value={location.surveyPoint}
              showCaret
              data-cy="Advance-SurveyPoint-Input"
              data-testid="location-sequence-auto-complete"
              error={!!errors.sequence}
              errorText={errors.sequence}
              disabled={viewOnly}
            />
          </Grid>
        )}
        <Grid item xs={inputSpacing} className={classes.itemSpacing}>
          <GroupedAutoComplete
            autoSelect={false}
            list={passList || []}
            label={isRocanville ? translate('Pass (optional)') : translate('Pass')}
            getOptionLabel={getMiningCutLabel}
            onChange={(event, value) => {
              onLocationChange('pass', value?.pass || {});
              onLocationChange('sequence', value?.sequence || {});
            }}
            value={{ sequence: location?.sequence || '', pass: location?.pass || '' }}
            showCaret
            data-cy="Advance-Chevron-Step"
            error={!!errors.step}
            errorText={errors.step}
            groupByProperty="miningPatternName"
            primaryProperty="sequencePassString"
            secondaryProperty="miningPatternName"
            disabled={viewOnly}
            data-testid="location-pass-auto-complete"
            selectOnFocus={false}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GenericLocationComponent;
