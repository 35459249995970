import { useEffect, useState } from 'react';
import { MangoQuery } from 'rxdb';
import { useRxCollection } from 'rxdb-hooks';

import { BlockDocument } from '../Blocks/queryBuilder';
import { RoomDocument } from '../Rooms/queryBuilder';
import { RxdbCollectionName } from '../rxdbCollectionName';
import { Panel, PanelCollection, PanelDocument } from './queryBuilder';

export interface OptionType {
  block?: BlockDocument;
  panel?: PanelDocument;
  room?: RoomDocument;
}

export interface OptionTypeWithMultipleRooms {
  block: BlockDocument;
  panel: PanelDocument;
  rooms?: RoomDocument[];
}

export const usePanels = (
  panelMiningMethod?: string,
  borerMiningMethod?: string,
  activeOnly = false,
) => {
  const panelsCollection: PanelCollection = useRxCollection(RxdbCollectionName.PANELS);
  const roomsCollection: PanelCollection = useRxCollection(RxdbCollectionName.ROOMS);

  const [augmentedPanelList, setAugmentedPanelList] = useState<OptionType[]>([]);
  // Lanigan
  const [roomList, setRoomList] = useState<OptionType[]>([]);
  const [roomListLoaded, setRoomListLoaded] = useState(false);
  // Cory / Vanscoy / Allan
  const [panelList, setPanelList] = useState<OptionType[]>([]);
  const [panelListLoaded, setPanelListLoaded] = useState(false);

  const [panelListPopulated, setPanelListPopulated] = useState<OptionTypeWithMultipleRooms[]>([]);

  const getRoomsForPanel = async (panelId: string) =>
    roomsCollection?.find().where('panel').eq(panelId).exec();

  const getRoomOptions = async (): Promise<any> => {
    if (!panelsCollection) return [];

    const options: OptionType[] = [];

    let panels;
    const queryParams: MangoQuery<Panel> = {
      selector: {},
    };

    if (activeOnly)
      queryParams.selector = {
        ...queryParams.selector,
        completedOn: { $eq: null },
      };

    try {
      panels = await panelsCollection?.find(queryParams).exec();
    } catch (error) {
      console.log('🚀 ~ file: usePanels.ts ~ line 24 ~ getRoomOptions ~ error', error);
    }
    if (!panels) return options;

    await Promise.all(
      panels.map(async panel => {
        const block: BlockDocument = await panel.populate('block');
        const rooms: RoomDocument[] = await getRoomsForPanel(panel.id);

        rooms.forEach(room => {
          options.push({
            block,
            panel,
            room,
          });
        });
      }),
    );

    return options;
  };

  const getPopulatedPanels = async (): Promise<OptionTypeWithMultipleRooms[]> => {
    if (!panelsCollection) return [];

    const options: OptionTypeWithMultipleRooms[] = [];

    let panels;
    try {
      panels = await panelsCollection?.find().exec();
    } catch (error) {
      console.error('🚀 ~ file: usePanels.ts ~ line 24 ~ getRoomOptions ~ error', error);
    }
    if (!panels) return options;

    await Promise.all(
      panels.map(async panel => {
        const block: BlockDocument = await panel.populate('block');
        const rooms: RoomDocument[] = await getRoomsForPanel(panel.id);

        options.push({
          block,
          panel,
          rooms: rooms || [],
        });
      }),
    );

    return options;
  };

  useEffect(() => {
    const getPanelData = async () => {
      setRoomListLoaded(false);
      setPanelListLoaded(false);
      setRoomList([]);
      setPanelList([]);
      const panelRoomList = await getRoomOptions();
      // Longroom

      setAugmentedPanelList(
        panelRoomList.sort(
          (a, b) =>
            a.panel.description.localeCompare(b.panel.description) ||
            a.room.description.localeCompare(b.room.description),
        ),
      );

      setRoomList(
        panelRoomList
          ?.filter((newRoom: OptionType) => {
            return (
              (borerMiningMethod ? newRoom.panel?.miningMethod === borerMiningMethod : true) &&
              newRoom.room?.isActive &&
              !newRoom.panel?.completedOn
            );
          })
          .sort(
            (a, b) =>
              a.block?.description?.localeCompare(b.block?.description) ||
              a.panel?.description?.localeCompare(b.panel?.description) ||
              a.room?.description?.localeCompare(b.room?.description),
          ),
      );
      setRoomListLoaded(true);

      // Chevron
      const queryParams: MangoQuery<Panel> = {
        selector: {},
      };

      if (activeOnly)
        queryParams.selector = {
          ...queryParams.selector,
          completedOn: { $eq: null },
        };

      if (panelMiningMethod)
        queryParams.selector = {
          ...queryParams.selector,
          miningMethod: panelMiningMethod,
        };

      const allPanels = (await panelsCollection?.find(queryParams).exec())?.sort((a, b) =>
        a.description.toLowerCase().localeCompare(b.description.toLowerCase()),
      );

      if (allPanels?.length && panelMiningMethod) {
        const filteredPanels = allPanels.map(panel => ({
          panel,
        }));
        setPanelList(filteredPanels);
      } else if (allPanels?.length) {
        setPanelList(
          allPanels.map(panel => ({ panel, block: panel.block, room: panel.room })) || [],
        );
      }

      const popPanels = await getPopulatedPanels();

      setPanelListPopulated(
        popPanels?.sort(
          (a, b) =>
            a.block?.description?.localeCompare(b.block?.description) ||
            a.panel?.description?.localeCompare(b.panel?.description),
        ),
      );

      setPanelListLoaded(true);
    };

    if (panelsCollection && roomsCollection) getPanelData();
  }, [panelsCollection, roomsCollection, panelMiningMethod, borerMiningMethod]);

  return {
    getRoomOptions,
    augmentedPanelList,
    panelList,
    panelListPopulated,
    roomList,
    roomListLoaded,
    panelListLoaded,
  };
};

export default usePanels;
