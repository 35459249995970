import { group, objectify, sort, unique } from 'radash';
import { useEffect, useState } from 'react';
import { useRxCollection } from 'rxdb-hooks';
import { Subscription } from 'rxjs';

import { useOnlineStatus } from '../../utilities';
import { getSurveyPointInt } from '../../utilities/utilityFunctions';
import { RxdbCollectionName } from '../rxdbCollectionName';
import useSurveyPoints from '../SurveyPoints/useSurveyPoints';
import { DocumentUploadCollection, DocumentUploadDocument, EnrichedDocument } from './queryBuilder';

export interface EnrichedDocumentByDocumentType {
  [key: string]: EnrichedDocument;
}

export const useDocsForLocation = (roomId?: string, surveyPointDesc?: string) => {
  const [docsForLocation, setDocsForLocation] = useState<DocumentUploadDocument[]>([]);
  const [docsForLocationLoaded, setDocsForLocationLoaded] = useState(false);
  const documentUploadCollection: DocumentUploadCollection = useRxCollection(
    RxdbCollectionName.DOCUMENT_UPLOAD,
  );

  const { surveyPointsForRoom } = useSurveyPoints(undefined, roomId);

  const isOnline = useOnlineStatus();

  const augmentAndSortDocuments = async (documents: DocumentUploadDocument[]) => {
    // Augment documents with documentTypeDescription and displayOrder
    await Promise.all(
      documents.map(async document => {
        const documentType = await document.populate('borerDocumentTypeId');
        if ((!document.documentTypeDescription || !document.displayOrder) && documentType) {
          document.atomicUpdate(oldDoc => ({
            ...oldDoc,
            documentTypeDescription: documentType.description,
            displayOrder: documentType.displayOrder,
          }));
        }
        return document;
      }),
    );

    return documents
      .filter(doc => doc !== null)
      .sort(
        (a, b) =>
          (a?.displayOrder === undefined ? 1 : 0) - (b?.displayOrder === undefined ? 1 : 0) ||
          +(a?.displayOrder > b?.displayOrder) ||
          -(a?.displayOrder < b?.displayOrder),
      );
  };

  useEffect(() => {
    let sub: Subscription | undefined;

    const getDocuments = async () => {
      if (!documentUploadCollection || !roomId) {
        setDocsForLocation([]);
        setDocsForLocationLoaded(true);
        return;
      }

      try {
        const querySelector = {
          selector: {
            roomId,
          },
        };

        sub = documentUploadCollection?.find(querySelector).$.subscribe(async docs => {
          // 1. All docs for given room
          const augmentedDocs = await augmentAndSortDocuments(docs);

          // If we know surveyPointDesc
          if (augmentedDocs.length && surveyPointDesc) {
            const relevantDocsByDocumentType: EnrichedDocumentByDocumentType = {};
            const otherDocs: EnrichedDocument[] = [];
            const currentSurveyPointDesc = getSurveyPointInt(surveyPointDesc);

            const roomSurveyPointsToInt = objectify(
              surveyPointsForRoom,
              sp => sp.id,
              sp => getSurveyPointInt(sp.description),
            );

            // 2. Organize them by survey point and sort from largest to smallest
            const docsBySurveyPoint = group(augmentedDocs, doc => {
              return roomSurveyPointsToInt[doc.surveyPointId];
            });

            // 3. Get the docs for the current room and survey point and add them to the relevantDocsByDocumentType
            docsBySurveyPoint[currentSurveyPointDesc]?.forEach(doc => {
              if (doc.documentTypeDescription === 'Other') {
                otherDocs.push(doc);
              } else {
                relevantDocsByDocumentType[doc.documentTypeDescription] = {
                  ...doc,
                  surveyPointDesc: currentSurveyPointDesc,
                  displayOrder: doc.displayOrder,
                  id: doc.id,
                };
              }
            });

            // 3.1 Determine which survey points need to be gone through from the current one to the smallest one
            const allSurveyPointsInts = Object.values(roomSurveyPointsToInt).sort();

            const indexOfCurrentSurveyPoint = allSurveyPointsInts.indexOf(currentSurveyPointDesc);
            const surveyPointsToCheck = allSurveyPointsInts
              .slice(0, indexOfCurrentSurveyPoint + 1)
              .reverse();
            // 4. Iterate of the docs by survey point from start to end and at each one
            //    check each doc at the survey point if it should carry over to the next survey point
            surveyPointsToCheck.forEach(surveyPointInt => {
              docsBySurveyPoint[surveyPointInt]?.forEach(doc => {
                if (doc.documentTypeDescription === 'Other') {
                  otherDocs.push({
                    ...doc,
                    surveyPointDesc: currentSurveyPointDesc,
                    displayOrder: doc.displayOrder,
                    id: doc.id,
                  });
                  return;
                }

                // Check if we already have a doc for this document type, if we do ignore it
                if (Object.keys(relevantDocsByDocumentType).includes(doc.documentTypeDescription)) {
                  return;
                }

                // Check if the doc should carry over to the next survey point
                const docStopSurveyPointInt = doc.stopcarryforwardSurveyPointId
                  ? roomSurveyPointsToInt[doc.stopcarryforwardSurveyPointId]
                  : null;

                // if null then it should carry over to the next survey point
                if (docStopSurveyPointInt === null) {
                  relevantDocsByDocumentType[doc.documentTypeDescription] = {
                    ...doc,
                    surveyPointDesc: surveyPointInt,
                    displayOrder: doc.displayOrder,
                    id: doc.id,
                  };
                  return;
                }

                // if the stop carry over survey point is greater than or equal to the current survey point then it should carry over
                if (docStopSurveyPointInt && docStopSurveyPointInt >= currentSurveyPointDesc) {
                  relevantDocsByDocumentType[doc.documentTypeDescription] = {
                    ...doc,
                    surveyPointDesc: surveyPointInt,
                    displayOrder: doc.displayOrder,
                    id: doc.id,
                  };
                  return;
                }

                // if the stop carry over survey point is less than the current survey point then it should not carry over
                if (docStopSurveyPointInt && docStopSurveyPointInt < currentSurveyPointDesc) {
                  return;
                }
              });
            });

            const sortedDocs = sort(
              Array.from(Object.values(relevantDocsByDocumentType)).concat(
                unique(otherDocs, doc => doc.id),
              ),
              doc => doc.displayOrder ?? 0,
            );

            // Use original RxDocuments
            const originalDocsSorted = sortedDocs
              .map(doc => augmentedDocs.find(augDoc => augDoc.id === doc.id))
              .filter(doc => doc !== undefined);

            setDocsForLocation(originalDocsSorted);
          } else if (docs.length) {
            // If we don't know surveyPointDesc
            setDocsForLocation(augmentedDocs);
          } else {
            setDocsForLocation([]);
          }
          setDocsForLocationLoaded(true);
        });
      } catch (error) {
        setDocsForLocation([]);
        console.log(error);
      }
    };
    getDocuments();

    return () => {
      if (sub?.unsubscribe) sub.unsubscribe();
    };
  }, [documentUploadCollection, roomId, surveyPointDesc, surveyPointsForRoom, isOnline]);

  return {
    docsForLocation,
    docsForLocationLoaded,
  };
};

export default useDocsForLocation;
