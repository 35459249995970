import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import postal from 'postal';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Borer } from '../../graphql/Equipment';
import { rootStore, useMst } from '../../mobx-models/Root';
import BorerSelector from '../BorerSelector';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      height: '100vh',
    },
    card: {
      margin: '80px auto',
      textAlign: 'center',
    },
  });
});

const Home: React.FC = () => {
  const classes = useStyles();

  const { equipment } = useMst();
  const history = useHistory();

  const [borerSelectOpen, setBorerSelectOpen] = useState(equipment.selectedBorerId === undefined);

  const setup = useCallback(async () => {
    try {
      // Only call if we don't have the list of borers from previous login step (ie page refresh)
      if (!equipment.borers.length) {
        await equipment.getListOfBorers(rootStore.user.siteId);
      }
    } catch (error) {
      console.log('🚀 ~ file: Home.tsx ~ line 36 ~ setup ~ error', error);
    }

    if (equipment.selectedBorerId !== undefined) {
      history.push('/initializing?initialLoad=true');
    }
  }, [equipment]);

  useEffect(() => {
    setup();
  }, [setup]);

  const assignBorer = (borer: Borer) => {
    equipment.selectBorer(borer.id);
    setBorerSelectOpen(false);

    Sentry.setContext('siteMetadata', {
      borerName: borer.shortName,
      siteName: rootStore.user.getSiteName(),
      siteId: rootStore.user.siteId,
    });

    postal.publish({
      channel: 'borer',
      topic: 'borer.selected',
    });

    history.push('/initializing?initialLoad=true');
  };

  return (
    <>
      <Grid item xs={8} md={6} className={classes.card}>
        <div>
          <img src="../../minesight_borer.svg" alt="MineSight Borer" />
        </div>
      </Grid>
      <BorerSelector
        open={borerSelectOpen}
        listOfBorers={equipment.borers.filter(borer => borer.isActive)}
        assignBorer={assignBorer}
      />
    </>
  );
};

export default observer(Home);
