export enum RxdbCollectionName {
  EMPLOYEES = 'employees',
  MINE_AREAS = 'mine_areas',
  MINING_CUTS = 'mining_cuts',
  SITES = 'sites',
  PASSES = 'passes',
  PANELS = 'panels',
  ROOMS = 'rooms',
  SEQUENCES = 'sequences',
  SURVEY_POINTS = 'survey_points',
  GROUND_HAZARDS = 'ground_hazards',
  GROUND_HAZARD_CONDITION_TYPES = 'ground_hazard_condition_types',
  HAZARD_LOGS = 'hazard_logs',
  GROUND_HAZARDS_ATTACHMENTS = 'ground_hazard_attachments',
  HAZARD_SEVERITIES = 'hazard_severities',
  LOCATIONS = 'locations',
  GROUND_CONTROL_TYPES = 'ground_control_types',
  GROUND_CONTROL_SETS = 'ground_control_sets',
  SIGNATURES = 'signatures',
  BORER_SHIFT_SIGNATURE = 'borer_shift_signature',
  SHIFT = 'shift',
  BORER_SHIFT = 'borer_shift',
  BORER_SHIFT_CREW = 'borer_shift_crew',
  BORER_SHIFT_CREW_MEMBER = 'borer_shift_crew_member',
  BLOCKS = 'blocks',
  INSPECTIONS = 'inspections',
  INSPECTION_CATEGORIES = 'inspection_categories',
  INSPECTION_OPTIONS = 'inspection_options',
  INSPECTION_RESULTS = 'inspection_results',
  EQUIPMENT = 'equipment',
  EQUIPMENT_DEFICIENCY = 'equipment_deficiency',
  EQUIPMENT_TYPE = 'equipment_type',
  EQUIPMENT_DEFICIENCY_LOG = 'equipment_deficiency_log',
  EQUIPMENT_DEFICIENCY_ATTACHMENT = 'equipment_deficiency_attachment',
  BORER_ACTIVITY = 'borer_activity',
  BORER_ACTIVITY_TYPE = 'borer_activity_type',
  DELAY_ACTIVITY_TYPE_CATEGORY = 'delay_activity_type_category',
  BORER_DELAY = 'borer_delay',
  BORER_DELAY_TYPE = 'borer_delay_type',
  WORK_ORDERS = 'work_orders',
  BORER_SHIFT_ACTIVITY_EMPLOYEES = 'borer_shift_activity_employees',
  BORER_SHIFT_ADVANCE = 'borer_shift_advance',
  BORER_SHIFT_PRODUCTION = 'borer_shift_production',
  BORER_SHIFT_PREDICTION = 'borer_shift_prediction',
  PRODUCTION_TARGET = 'borer_shift_production_target',
  PANEL_DRAWINGS = 'panel_drawings',
  PANEL_DRAWING_COMMENTS = 'panel_drawing_comments',
  ASSIGNMENT_EMPLOYEE = 'assignment_employee',
  ASSIGNMENT_ROLE = 'assignment_role',
  ASSIGNMENT = 'assignment',
  DAILY_SAFETY_TOPIC = 'daily_safety_topic',
  BORER_SHIFT_INFO = 'borer_shift_info',
  DOCUMENT_UPLOAD = 'document_upload',
  DOCUMENT_TYPES = 'document_types',
  SUPPLIES = 'supplies',
  SUPPLY_ITEMS = 'supply_items',
  SERVICE_STATUS = 'service_status',
  TARGET_BORER_RUNNING_TIMES = 'target_borer_running_times',
  CREWS = 'crews',
  POSITIONS = 'positions',
  MINING_PATTERN = 'mining_pattern',
  DEPARTMENT = 'department',
  BORER_SHIFT_COMMENT = 'borer_shift_comment',
  BORER_SHIFT_COMMENT_TYPE = 'borer_shift_comment_type',
  INSPECTION_SEVERITY = 'inspection_severity',
  BORER_OPERATOR_STATE_FEED = 'borer_operator_state_feed',
  BORER_STATE_TYPE = 'borer_state_type',
  BORER_OPERATOR_CHANGE_FEED = 'borer_operator_change_feed',
  BORER_STATE_TYPE_CATEGORY = 'borer_state_type_category',
  PANEL_LOGS = 'panel_logs',
  CUTTING_TYPE = 'cutting_type',
  CUTTING_METHOD = 'cutting_method',
}

export default RxdbCollectionName;
