import dayjs from 'dayjs';
import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface Advance extends BaseEntity {
  readonly borerShiftProductionId: string;
  distance: number;
  readonly createdOn: string;
  startDistance: number | null;
  endDistance: number | null;
}
export type AdvanceCollection = RxCollection<Advance> | null;
export type AdvanceDocument = RxDocument<Advance>;

export const advanceSchema: RxJsonSchema<Advance> = {
  type: 'object',
  version: 5,
  description: 'describes an Advance object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    borerShiftProductionId: { type: 'string' },
    distance: { type: 'number' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    createdOn: { type: 'string' },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    startDistance: { type: ['number', 'null'] },
    endDistance: { type: ['number', 'null'] },
  },
  indexes: ['updatedAt'],
};

export const advancesPullQueryBuilder = (doc: Advance) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }

  const query = print(gql`
    query borerShiftAdvanceFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      borerShiftAdvanceFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        borerEquipmentId: $borerEquipmentId
      ) {
        borerShiftProductionId
        distance
        id
        isDeleted
        updatedAt
        version
        createdOn
        startDistance
        endDistance
      }
    }
  `);

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const advancesPushQueryBuilder = (doc: AdvanceDocument) => {
  doc = doc[0];

  const query = print(gql`
    mutation setBorerShiftProductionAdvance($advance: SetBorerShiftAdvanceInput) {
      setBorerShiftProductionAdvance(input: $advance) {
        id
        borerEquipmentId
        siteId
      }
    }
  `);

  const variables = {
    advance: doc,
  };

  return {
    query,
    variables,
  };
};

export const advancesPushModifier = (doc: AdvanceDocument) => {
  const obj = {
    ...doc,
    modifiedOn: dayjs().toISOString(),
    isDeleted: doc.isDeleted,
  };

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev', '_meta'];
  propertyBlackList.forEach(property => {
    obj[property] = undefined;
  });

  return obj;
};

export const advanceMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: AdvanceDocument) => ({
    ...oldDoc,
    startFootage: oldDoc.startFootage || '',
    endFootage: oldDoc.endFootage || '',
  }),
  2: (oldDoc: AdvanceDocument) => ({
    ...oldDoc,
    startFootage: oldDoc.startFootage || '',
    endFootage: oldDoc.endFootage || '',
  }),
  3: (oldDoc: AdvanceDocument) => ({
    ...oldDoc,
  }),
  4: (oldDoc: AdvanceDocument) => ({
    ...oldDoc,
    distance: oldDoc.footage,
    startDistance: oldDoc.startFootage,
    endDistance: oldDoc.endFootage,
  }),
  5: (oldDoc: AdvanceDocument) => {
    // for each doc check if start startDistance or end startDistance are strings or undefined
    let startDistance = oldDoc.startDistance;
    let endDistance = oldDoc.endDistance;

    // if a string, convert to number
    if (typeof startDistance === 'string') {
      startDistance = parseFloat(startDistance);
    }
    if (typeof endDistance === 'string') {
      endDistance = parseFloat(endDistance);
    }

    // if undefined, set to null
    if (typeof startDistance === 'undefined') {
      startDistance = null;
    }
    if (typeof endDistance === 'undefined') {
      endDistance = null;
    }

    // if number, do nothing

    return {
      ...oldDoc,
      startDistance,
      endDistance,
    };
  },
};

export default {};
