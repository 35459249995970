import { flow, getParent, types } from 'mobx-state-tree';

import { listBorers, ListBorersResult } from '../../graphql/Equipment';
import { ApiStatus } from '../../utilities/apiStatus';
import { BORER_PAGINATION_LIMIT, EMPTY_GUID } from '../../utilities/constants';
import { sortAlphabeticallyByProperty } from './../../utilities/sortHelper';

const MiningMethodModel = types.model({
  layer: types.string,
  name: types.string,
  zone: types.string,
});

const BlockModel = types.model({
  description: types.maybeNull(types.string),
});

const PanelModel = types.model({
  id: types.string,
  description: types.maybeNull(types.string),
  miningMethod: types.maybeNull(MiningMethodModel),
  block: types.maybeNull(BlockModel),
});
const Borer = types.model({
  id: types.identifier,
  longName: types.string,
  shortName: types.maybeNull(types.string),
  assignedPanel: types.maybeNull(PanelModel),
  isActive: types.boolean,
});

export const SELECTED_BORER_ID_KEY = 'SELECTED_BORER_ID_KEY';

export const Equipment = types
  .model({
    BorersMap: types.map(Borer),
    BorersFetchStatus: types.enumeration('STATUS', [
      ApiStatus.NOT_CALLED,
      ApiStatus.LOADING,
      ApiStatus.ERROR,
      ApiStatus.SUCCESS,
    ]),
    selectedBorerId: types.maybe(types.string),
  })
  .views(self => ({
    get borers() {
      return sortAlphabeticallyByProperty(Array.from(self.BorersMap.values()), 'longName');
    },
    get selectedBorerAssignedPanel() {
      if (!self.selectedBorerId) return '';
      const borer = self.BorersMap.get(self.selectedBorerId);
      return borer?.assignedPanel;
    },
    get selectedBorerName() {
      if (!self.selectedBorerId) return '';
      const borer = self.BorersMap.get(self.selectedBorerId);

      if (!borer) return '';

      if (borer.shortName) return borer.shortName;
      return borer.longName;
    },
    get selectedBorerMiningMethod() {
      if (!self.selectedBorerId) return '';
      const borer = self.BorersMap.get(self.selectedBorerId);

      if (!borer) return '';

      if (borer.assignedPanel?.miningMethod?.name) {
        return borer.assignedPanel?.miningMethod?.name;
      }
      return '';
    },
  }))
  .actions(self => ({
    getListOfBorers: flow(function* getListOfBorers() {
      const rootStore = getParent(self, 1);
      const siteId = rootStore.user.siteId;

      if (self.BorersFetchStatus === ApiStatus.LOADING) {
        return;
      }

      let lastId = EMPTY_GUID;
      let borersCount = BORER_PAGINATION_LIMIT;
      self.BorersFetchStatus = ApiStatus.LOADING;
      self.BorersMap.clear();

      try {
        while (borersCount === BORER_PAGINATION_LIMIT) {
          const result: ListBorersResult = yield listBorers(siteId, BORER_PAGINATION_LIMIT, lastId);

          const borers = result.data.listEquipmentForType;
          borers.forEach(borer => {
            self.BorersMap.set(borer.id, borer);
          });
          borersCount = borers.length;
          lastId = borers[borersCount - 1]?.id;
        }

        self.BorersFetchStatus = ApiStatus.SUCCESS;
      } catch (error) {
        console.log('🚀 ~ file: GroundControls.ts ~ line 94 ~ error', error);
        self.BorersFetchStatus = ApiStatus.ERROR;
      }
    }),
    selectBorer: (borerId: string) => {
      self.selectedBorerId = borerId;

      const borer = self.BorersMap.get(borerId);

      localStorage.setItem(SELECTED_BORER_ID_KEY, JSON.stringify(borer));
    },
    resetStore: () => {
      self.BorersMap.clear();
      self.BorersFetchStatus = ApiStatus.NOT_CALLED;
      self.selectedBorerId = undefined;
    },
  }));

export default Equipment;
