import type { RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { rootStore } from '../../mobx-models/Root';
import { BaseEntity } from '../../models/BaseEntity';
import { SYNC_LIMIT_LOW } from '../../utilities/constants';
import defaultDoc from '../Shared/defaultDoc';

export interface Production extends BaseEntity {
  readonly borerShiftId: string;
  readonly locationId: string;
  readonly miningCutId: string;
}
export type ProductionCollection = RxCollection<Production> | null;
export type ProductionDocument = RxDocument<Production>;

export const productionSchema: RxJsonSchema<Production> = {
  type: 'object',
  version: 0,
  description: 'describes a Production object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    borerShiftId: { type: 'string' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    locationId: {
      ref: 'locations',
      type: 'string',
    },
    miningCutId: {
      ref: 'mining_cuts',
      type: 'string',
    },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
  },
  indexes: ['updatedAt'],
};

export const productionPullQueryBuilder = (doc: Production) => {
  if (!doc) {
    doc = { ...defaultDoc };
  }
  const query = `
    query borerShiftProductionFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
      borerShiftProductionFeed(
        lastUpdateAt: $lastUpdateAt
        limit: $limit
        borerEquipmentId: $borerEquipmentId
      ) {
          borerShiftId
          id
          locationId
          miningCutId
          isDeleted
          updatedAt
          version
      }
    }
  `;

  return {
    query,
    variables: {
      borerEquipmentId: rootStore.equipment.selectedBorerId,
      lastUpdateAt: doc.updatedAt,
      limit: SYNC_LIMIT_LOW,
    },
  };
};

export const productionPushQueryBuilder = (doc: Production) => {
  doc = doc[0];

  const query = `
      mutation setBorerShiftProduction($production: SetBorerShiftProductionInput) {
        setBorerShiftProduction(input: $production)
      }`;

  const variables = {
    production: doc,
  };

  return {
    query,
    variables,
  };
};

export const productionPullModifier = (doc: any) => {
  const result = {
    ...doc,
  };
  return result;
};

export const productionPushModifier = (doc: any) => {
  const result = doc;

  const propertyBlackList = ['updatedAt', '_deleted', '_attachments', '_rev', '_meta'];
  propertyBlackList.forEach(property => {
    result[property] = undefined;
  });

  return result;
};

export default {};
